import React from "react";
import Axios from 'axios';
import AuthService from '../Auth/AuthService';

const usePageApi = (url, pageNumber, pageSize, sortOrder, sortProperty, searchString, archived = false, refresh = null) => {

    const [loading, setLoading] = React.useState(false);
    const [rows, setRows] = React.useState([]);
    const [totalCount, setTotalCount] = React.useState(0);
    const [errorOpen, setErrorOpen] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState('');

    React.useEffect(() => {
        setLoading(true);
        Axios.request({
            url: url,
            method: 'get',
            headers: {
                ...AuthService.getAuthHeader()
            },
            params: {
                pageNumber, pageSize, sortOrder, sortProperty, searchString, archived
            }
        }).then((resp) => {
            if (resp.data) {
                setRows(resp.data.content);
                setTotalCount(resp.data.totalElements);
                setLoading(false);
            }
        }).catch((error) => {
            setErrorMessage(error.response.data.message);
            setErrorOpen(true);
            setLoading(false);
        });
    }, [url, pageNumber, pageSize, sortOrder, sortProperty, searchString, archived, refresh]);

    return [loading, rows, totalCount, errorOpen, errorMessage];

};

export default usePageApi;
