import Axios from "axios";

import jwtDecode from 'jwt-decode';

class AuthService {

    static async login(email, password, authToken) {
        return Axios.post("/api/auth/login", { email, password, authToken });
    }

    static async logout() {
        localStorage.removeItem('authToken');
    }

    static setAuthToken(token) {
        localStorage.setItem('authToken', token);
    }

    static getAuthToken() {
        return localStorage.getItem('authToken');
    }

    static hasToken() {
        const token = localStorage.getItem('authToken');
        if (!token) return false;
        const decoded = jwtDecode(token);
        return decoded.exp < (new Date()).getTime();
    }

    static async getProfile() {
        if (!this.hasToken()) return false;

        return Axios.request({
            url: '/api/auth/profile',
            headers: {
                Authorization: `Bearer ${this.getAuthToken()}`
            }
        });
    }

    static getAuthHeader() {
        return {
            Authorization: `Bearer ${this.getAuthToken()}`
        }
    }

    static async checkIfLoggedIn() {


        if (AuthService.hasToken()) {
            return AuthService.getProfile().catch(() => {
                return (Promise.reject("not logged in"));
            });
        }
        return (Promise.reject("not logged in"));
    }

    static async remove2FaConfirmation() {
        return Axios.request({
            headers: { ...AuthService.getAuthHeader() },
            method: 'get',
            url: '/api/auth/reset-auth-token',
        });
    }

}

export default AuthService;
