import { Button, IconButton, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import LoadingIndicator from '../components/LoadingIndicator';
import useApi from '../hooks/useApi';
import CloseIcon from '@material-ui/icons/Close';
import mifirStyles from '../utils/styles';
import TransactionDetails from './TransactionDetails';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus as codeStyle } from 'react-syntax-highlighter/dist/esm/styles/prism';
import Alert from '@material-ui/lab/Alert';
import SendIcon from '@material-ui/icons/Send';
import CancelIcon from '@material-ui/icons/Cancel'
import TransactionService from './TransactionService';

const TransactionCancellation = (props) => {

    const { id, setOpen } = props;
    const [isLoading, data] = useApi(`/api/transaction/build-cancellation/${id}`);
    const [isSending, setIsSending ] = useState(false);
    const [iSendingDisabled, setSendingDisabled] = useState(false);
    const classes = mifirStyles();

    const cancelReport = async () => {
        setIsSending(true);
        setSendingDisabled(true);
        await TransactionService.cancelTransaction(id).catch((error) => {
            setIsSending(false);
            alert("a technical error occured while sending transaction: " + error);
        });
        history.go(0);
    }

    return(
       <>
            {(isLoading || isSending)&&
                <LoadingIndicator/>
            }
            {!isLoading && data &&
                <>
                <div className={classes.modalHeader}>
                    <IconButton onClick={() => setOpen(false)}>
                        <CloseIcon />
                    </IconButton>
                </div>
                <Typography variant="h5">Cancel Transaction Report</Typography>
                {data && data.xml &&
                        <>
                            <Alert className={`${classes.spacingTop} ${classes.spacingBottom}`} color="warning">WARNING: You are cancelling the report for the transaction with the details below.</Alert>
                            <TransactionDetails transaction={data.transaction} className={classes.paddingTop}/>

                            <Typography className={classes.header} variant="body1">Preview Cancellation XML</Typography>
                            <SyntaxHighlighter className={classes.xmlCode} language="xml" style={codeStyle}>
                                {data.xml}
                            </SyntaxHighlighter>



                            <Button
                                disabled={iSendingDisabled}
                                className={classes.actionButton}
                                variant="contained"
                                color="primary"
                                onClick={cancelReport}
                                startIcon={<SendIcon />}>
                                    Cancel Report
                            </Button>
                            <Button
                                disabled={iSendingDisabled}
                                className={classes.actionButton}
                                variant="contained"
                                color="secondary"
                                onClick={() => setOpen(false)}
                                startIcon={<CancelIcon/>}
                            >
                                Abort
                            </Button>

                        </>
                    }


                </>
            }
       </>
    );
}


TransactionCancellation.propTypes = {
    setOpen: PropTypes.func,
    id: PropTypes.string,
};

export default TransactionCancellation;


