import PropTypes from 'prop-types';

export const TradingPartyType = {
    LEI: 'LEI',
    MIC: 'MIC',
    NATIONAL_ID: 'NATIONAL_ID'
}

export const NationalIdType = {
    CONCAT: 'CONCAT',
    CCPT: 'Passport Number',
    CUST: 'Customer Identification Number',
    NIDN: 'National Identity Number',
    SOSE: 'Social Security Number',
    TXID: 'Tax Identification Number'
  }

export const tradingPartyTypeLabel = {};
tradingPartyTypeLabel[TradingPartyType.LEI] = "Investment Firm";
tradingPartyTypeLabel[TradingPartyType.MIC] = "Market";
tradingPartyTypeLabel[TradingPartyType.NATIONAL_ID] = "Person";

export const getTradingPartyKeyByValue = (value) => {
    return Object.keys(TradingPartyType).find( key => TradingPartyType[key] === value);
}

export const TradingPartyPropsType = {
    birthdate: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    typeFilter: PropTypes.arrayOf(TradingPartyType)
}
