import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useImperativeHandle } from 'react';

const ConfirmationDialog = forwardRef(({title, question, onClose},ref) => {
    
    const show = () => {
        setOpen(true);
    }

    useImperativeHandle(
        ref,
        () => ({
            show
        })
    );

    const [isOpen,setOpen] = useState(false);

    const handleClose = (answer) => {
        setOpen(false);
        onClose(answer);
    }
    
    return (
        <span ref={ref}>
            <Dialog
                open={isOpen}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {question}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleClose(true)}>Yes</Button>
                    <Button onClick={() => handleClose(false)}>No</Button>
                </DialogActions>
            </Dialog>    
        </span>
    );
});

ConfirmationDialog.displayName = 'ConfirmationDialog';
ConfirmationDialog.propTypes = {
    question: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
}

export default ConfirmationDialog;