import { CircularProgress } from "@material-ui/core";
import React from 'react';
import mifirStyles from "../utils/styles";

const LoadingIndicator = () => {

    const classes = mifirStyles();
    return (
        <div className={classes.centerOnScreen}><CircularProgress/></div>
    );
}

export default LoadingIndicator;