import { Container, Grid, Tooltip, Typography } from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import PaymentIcon from '@material-ui/icons/Payment';
import PeopleIcon from '@material-ui/icons/People';
import dateformat from 'dateformat';
import PropTypes from 'prop-types';
import React from 'react';
import { formatNumber, formatPrice, formatQuantity } from '../utils/Formatter';
import mifirStyles from '../utils/styles';
import { OTCPostTradeIndicatorTypes } from './TransactionType';




const TransactionDetails = ({ transaction }) => {

    const { isin, tradingDateTime, price, currency, quantity, buyers, sellers, otcPostTradeIndicator, isDebt, netAmount } = transaction;

    const classes = mifirStyles();
    return (
        <Container className={classes.detailsContainer}>
            <Typography variant="h6" className={classes.tradingDetailsTitle}>Trading Details</Typography>
            <Grid container>
                <Grid container item sm={6}>
                    <Grid item><AssignmentOutlinedIcon fontSize="small" className={classes.detailsIcon} /></Grid>
                    <Grid item><Typography variant="body2">{isin ? isin : '-'}</Typography></Grid>
                </Grid>
                <Grid container item sm={6}>
                    <Grid item><AccessTimeIcon fontSize="small" className={classes.detailsIcon} /></Grid>
                    <Grid item><Typography variant="body2">{dateformat(new Date(tradingDateTime))}</Typography></Grid>
                </Grid>
                <Grid container item sm={6}>
                    <Grid item><PaymentIcon fontSize="small" className={classes.detailsIcon} /></Grid>
                    <Grid item>
                        <Typography variant="body2">
                            {!isDebt &&
                                <>
                                    {(price && currency) ? formatPrice(price, currency) : '-'} ({formatNumber(quantity)})
                                </>
                            }
                            {isDebt &&
                                <>
                                    {(quantity && currency && price) ? <Tooltip title="Nominal"><span>{formatPrice(price, currency, transaction)} - {formatQuantity(quantity, currency, transaction)}</span></Tooltip> : '-'}
                                    {(netAmount && currency) ? <Tooltip title="Net Amount"><span style={{ fontSize: '12px' }}><br />({formatQuantity(netAmount, currency, transaction)})</span></Tooltip> : ''}

                                </>
                            }
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container item sm={6}>
                    <Grid item><AccountBalanceIcon fontSize="small" className={classes.detailsIcon} /></Grid>
                    <Grid item><Typography variant="body2">{otcPostTradeIndicator && otcPostTradeIndicator.length ? otcPostTradeIndicator.map(otc => OTCPostTradeIndicatorTypes[otc]).join(', ') : '-'}</Typography></Grid>
                </Grid>
                <Grid container item sm={6}>
                    <Grid item><PeopleIcon fontSize="small" className={classes.detailsIcon} /></Grid>
                    <Grid item><Typography variant="body2">Buyers: {(buyers && buyers.length) ? buyers.map(buyer => buyer.name).join(', ') : '-'}</Typography></Grid>
                </Grid>
                <Grid container item sm={6}>
                    <Grid item><PeopleIcon fontSize="small" className={classes.detailsIcon} /></Grid>
                    <Grid item><Typography variant="body2">Sellers: {(sellers && sellers.length) ? sellers.map(seller => seller.name).join(', ') : '-'}</Typography></Grid>
                </Grid>
            </Grid>
        </Container>
    );
};

export default TransactionDetails;

TransactionDetails.propTypes = {
    transaction: PropTypes.any.isRequired
}