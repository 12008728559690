import { makeStyles, createStyles } from "@material-ui/core/styles";
import { green, red, yellow } from "@material-ui/core/colors";


const mifirStyles = makeStyles(theme => createStyles({
    content: {
        marginTop: theme.spacing(10)
    },
    error: {
        color: theme.palette.getContrastText(red[500]) + " !important",
        backgroundColor: red[500] + " !important"
    },
    success: {
        color: theme.palette.getContrastText(green[900]) + " !important",
        backgroundColor: green[900] + " !important"
    },
    warning: {
        backgroundColor: yellow[800] + " !important"
    },
    reportCard: {
        marginBottom: theme.spacing(4),
        marginTop: theme.spacing(2)
    },
    info: {
        color: theme.palette.grey[500],
        fontSize: '10pt'
    },
    xmlCode: {
        maxHeight: window.innerHeight - 530 + 'px',
        overflow: 'scroll',
        width: '100%',
        boxSizing: 'border-box',
    },
    detailsContainer: {
        marginBottom: theme.spacing(2)
    },
    topAccordion: {
        alignItems: 'center'
    },
    dividerWithMargin: {
        marginBottom: theme.spacing(2)
    },
    menuButton: {
        marginRight: theme.spacing(2)
    },
    avatar: {
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'flex-end',
    },
    toolbar: {
        ...theme.mixins.toolbar,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-end'
    },
    appLogo: {


    },
    menuLabel: {
        flex: 1,
        flexGrow: 1,
        justifyContent: 'center',
        alignContent: 'center'
    },
    drawer: {

    },
    loginComponent: {
        marginTop: theme.spacing(15),
        paddingTop: theme.spacing(2)
    },
    root: {
        marginTop: theme.spacing(4),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1)
    },
    form: {},
    marginY: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    marginTop: {
        marginTop: theme.spacing(2),
    },
    marginBottom: {
        marginBottom: theme.spacing(2),
    },
    marginLeft: {
        marginLeft: theme.spacing(2)
    },
    flexShrink: {
        flexShrink: 1,
    },
    text: {
        margin: theme.spacing(2),
        display: 'flex'
    },
    loginTitle: {
        marginTop: '50px'
    },
    tokenAuth: {
        marginTop: theme.spacing(-2),
        marginBottom: theme.spacing(2),
        paddingLeft: theme.spacing(2),

    },
    centerOnScreen: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        margin: 0,
        padding: 0,
        position: 'absolute',
        width: '100%',
        height: 'calc(100% - 84px)'
    },
    noPaddingX: {
        paddingLeft: 0,
        paddingRight: 0
    },
    tokenInput: {
        marginRight: theme.spacing(1),
        width: "80px",
        textAlign: 'center'
    },

    singleButton: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    spacingTop: {
        marginTop: theme.spacing(2)
    },
    spacingBottom: {
        marginBottom: theme.spacing(2)
    },
    title: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(1)
    },
    input: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        display: 'flex'
    },
    button: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        marginRight: theme.spacing(1)
    },
    textField: {
        display: 'flex',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    formContainer: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    formLabel: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    header: {
        paddingTop: theme.spacing(2)
    },
    tableHeader: {
        fontWeight: 600
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(4),
        right: theme.spacing(4)
    },
    detailsIcon: {
        marginRight: theme.spacing(1)
    },

    tradingDetailsTitle: {
        marginBottom: theme.spacing(1)
    },
    container: {
        marginBottom: theme.spacing(2),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    field: {
        display: 'flex',
        marginBottom: theme.spacing(2)
    },
    actionButton: {
        marginTop: theme.spacing(3),
        marginRight: theme.spacing(1)
    },

    chip: {
        marginRight: theme.spacing(0.5)
    },
    errorCollapse: {
        paddingBottom: theme.spacing(2)
    },
    searchForm: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    modalStyle: {
        width: '90%',
        position: 'absolute',
        outline: 0,
        backgroundColor: theme.palette.background.paper,
        border: 'none',
        padding: theme.spacing(2, 4, 3),
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        overflowY: 'scroll',
        maxHeight: '95%'

    },
    modalHeader: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    previewAccordion: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    sendIndicator: {
        //paddingTop: theme.spacing(2)
    },
    errorAvatar: {
        backgroundColor: red[500],
        color: theme.palette.getContrastText(red[500]) + " !important"
    },
    reportsHeader: {
        lineHeight: 0.9,
        marginRight: theme.spacing(1)
    },
    heading: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2)
    },
    qrCodeContainer: {
        paddingLeft: 0,
        paddingRight: 0,
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4)
    },
    twoFaActiveMessage: {
        verticalAlign: 'middle',
        marginBottom: theme.spacing(1)
    },
    tradingPartySelect: {
        marginBottom: theme.spacing(2)
    },
    tradingPartyOption: {
        flex: 1,
        justifyContent: 'space-between'
    },
    editTradingPartyButton: {
        float: 'right'
    },
    optionLabel: {
        float: 'left'
    },
    paddingTop: {
        paddingTop: theme.spacing(2)
    },
    paddingBottom: {
        paddingBottom: theme.spacing(2)
    },
}));

export default mifirStyles;