export const OTCPostTradeIndicatorTypes = {
    BENC: 'Benchmark',
    ACTX: 'Agency',
    LRGS: 'Large in scale',
    ILQD: 'Illiquid instrument',
    SIZE: 'Above specified size',
    CANC: 'Cancellations',
    AMND: 'AMAmendments',
    SDIV: 'Special dividend',
    RPRI: 'Price improvement',
    DUPL: 'Duplicative',
    TNCP: 'Not contributing to the price discovery process',
    TPAC: 'Package',
    XFPH: 'Exchange for Physical'
}

export const TransactionReportStatus = {
        NEW: 0,
        SENT: 1,
        ACCEPTED: 2,
        CANCELED: -1,
        ERROR: -2
};