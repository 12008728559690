import React from 'react';
import * as Axios from 'axios';
import AuthService from '../Auth/AuthService';

const useApi = (url) => {

    const [isLoading, setIsLoading] = React.useState(true);
    const [data, setData] = React.useState(null);

    React.useEffect(() => {
        Axios.request({
            url: url,
            method: 'get',
            headers: {
                ...AuthService.getAuthHeader()
            }
        }).then(response => {
            setIsLoading(false);
            setData(response.data);
        }).catch(error => {
            setIsLoading(false);
            throw(error);
        });

    }, [url]);

    return [isLoading, data];

}

export default useApi;