import Container from "@material-ui/core/Container";
import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import './App.css';
import Login from "./Auth/Login";
import PrivateRoute from "./Auth/PrivateRoute";
import Heading from "./Heading";
import Settings from './Settings/Settings';
import TradingPartyEdit from "./TradingParty/TradingPartyEdit";
import TradingPartyList from "./TradingParty/TradingPartyList";
import TransactionEdit from "./Transaction/TransactionEdit";
import TransactionList from "./Transaction/TransactionList";
import UserEdit from "./User/UserEdit";
import UserList from "./User/UserList";
import mifirStyles from './utils/styles';


const App = () => {

    const classes = mifirStyles();

    return (
        <Router>
            <Heading />
            <Container className={classes.content}>

                <PrivateRoute path="/" exact>
                    <TransactionList />
                </PrivateRoute>

                <PrivateRoute path="/transaction/:transactionId" exact>
                    <TransactionEdit />
                </PrivateRoute>

                <PrivateRoute path="/user" exact>
                    <UserEdit isNew={false} />
                </PrivateRoute>

                <PrivateRoute path="/user/:userId(\d+?)" exact>
                    <UserEdit isNew={false} />
                </PrivateRoute>

                <PrivateRoute path="/user/new" exact>
                    <UserEdit isNew={true} isDialog={false} />
                </PrivateRoute>

                <PrivateRoute path="/users" exact>
                    <UserList />
                </PrivateRoute>

                <PrivateRoute path="/settings" exact>
                    <Settings />
                </PrivateRoute>

                <PrivateRoute path="/trading-party" exact>
                    <TradingPartyList />
                </PrivateRoute>

                <PrivateRoute path="/trading-party/new" exact>
                    <TradingPartyEdit isNew={true} />
                </PrivateRoute>

                <PrivateRoute path="/trading-party/:tradingPartyId(\d+?)" exact>
                    <TradingPartyEdit isNew={false} />
                </PrivateRoute>

                <Route path="/login" exact>
                    <Login />
                </Route>

            </Container>
        </Router>
    );
}

export default App;
