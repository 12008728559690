import { Button, Container, FormControl, InputLabel, MenuItem, Paper, Select, TextField, Typography } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import SendIcon from '@material-ui/icons/Send';
import React from 'react';
import { useHistory } from 'react-router-dom';
import LoadingIndicator from '../components/LoadingIndicator';
import mifirStyles from '../utils/styles';
import { SettingsService } from './SettingsService';



const targetSystems = [
    {
        name: "Testsystem",
        value: "MIFIRTEST"
    },
    {
        name: "Production",
        value: "MIFIRPROD"
    }
];

const provider = [
    {
        name: "editel (FMA)",
        value: "editel"
    },
    {
        name: "BaFin",
        value: "bafin"
    }
]


const Settings = () => {


    const [loading, setLoading] = React.useState(true)
    const [settings, setSettings] = React.useState({
        executingEntityLei: '',
        provider: 'editel',
        targetSystem: 'MIFIRTEST',
        senderEdiAddress: '',
        pgpMifirKey: '',
        ediUsername: '',
        ediPassword: '',
        interchangeControlReferencePrefix: 0,
        bafinUsername: '',
        bafinPassword: '',
        bafinMeldepflichtiger: '',
        instanceId: '',
        debugProxyEnabled: false,
        debugProxyProtocol: 'https',
        debugProxyHostname: '',
        debugProxyPort: ''
    });

    const history = useHistory();

    React.useEffect(() => {
        SettingsService.getSettings().then(response => {
            const loadedSettings = {};
            response.data.map(setting => loadedSettings[setting.key] = setting.value);
            setSettings({ ...settings, ...loadedSettings });
            console.log(loadedSettings);
            setLoading(false);
        });
    }, []);


    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        SettingsService.saveSettings(settings).then(() => {
            setLoading(false);
            history.goBack();
        });
    }

    const handleChange = (event) => {
        console.log(event.target.name);

        setSettings({ ...settings, [event.target.name]: event.target.value });
    }

    const classes = mifirStyles();
    return (
        <>
            {loading ? <LoadingIndicator /> :
                <form noValidate onSubmit={handleSubmit} className={classes.root}>
                    <Typography className={classes.title} variant="h5">Settings</Typography>
                    <Container component={Paper} maxWidth="lg" className={`${classes.paddingTop} ${classes.paddingBottom}`}>

                        <TextField
                            className={classes.input}
                            name="executingEntityLei"
                            label="Executing Entity LEI"
                            value={settings.executingEntityLei}
                            onChange={handleChange}
                        />

                        <TextField
                            className={classes.input}
                            name="interchangeControlReferencePrefix"
                            label="Interchange Control Reference Prefix"
                            value={settings.interchangeControlReferencePrefix}
                            type="number"
                            onChange={handleChange}
                        />

                        <FormControl className={classes.input}>
                            <InputLabel id="targetSystemLabel">Target System</InputLabel>
                            <Select
                                labelId="targetSystemLabel"
                                value={settings.targetSystem}
                                name="targetSystem"
                                onChange={handleChange}
                            >
                                {targetSystems.map(targetSystem =>
                                    <MenuItem key={targetSystem.value} value={targetSystem.value}>{targetSystem.name}</MenuItem>
                                )}
                            </Select>
                        </FormControl>

                        <FormControl className={classes.input}>
                            <InputLabel>Provider</InputLabel>
                            <Select
                                name="provider"
                                value={settings.provider}
                                onChange={handleChange}
                            >
                                {provider.map(prov => <MenuItem key={prov.value} value={prov.value}>{prov.name}</MenuItem>)}
                            </Select>

                        </FormControl>
                    </Container>

                    {settings.provider === "bafin" && (
                        <>
                            <Typography variant="h6" className={classes.title}>BaFin Settings</Typography>
                            <Container component={Paper} maxWidth="lg" className={`${classes.paddingTop} ${classes.paddingBottom}`}>

                                <TextField
                                    className={classes.input}
                                    name="bafinUsername"
                                    label="BaFin username"
                                    value={settings.bafinUsername}
                                    onChange={handleChange}
                                />

                                <TextField
                                    className={classes.input}
                                    name="bafinMeldepflichtiger"
                                    label="BaFin Meldepflichtiger"
                                    value={settings.bafinMeldepflichtiger}
                                    onChange={handleChange}
                                    type="text"
                                />

                                <TextField
                                    className={classes.input}
                                    name="bafinPassword"
                                    label="BaFin password"
                                    value={settings.bafinPassword}
                                    onChange={handleChange}
                                    type="password"
                                />
                                <TextField
                                    className={classes.input}
                                    name="instanceId"
                                    label="Identifier for installation instance"
                                    value={settings.instanceId}
                                    onChange={handleChange}
                                    type="number"
                                />



                            </Container>
                        </>
                    )}

                    {settings.provider === "editel" && (
                        <>
                            <Typography variant="h6" className={classes.title}>Editel Settings</Typography>
                            <Container component={Paper} maxWidth="lg" className={`${classes.paddingTop} ${classes.paddingBottom}`}>
                                <TextField
                                    className={classes.input}
                                    name="senderEdiAddress"
                                    label="EDI Address Of Sender"
                                    value={settings.senderEdiAddress}
                                    onChange={handleChange}
                                />

                                <TextField
                                    className={classes.input}
                                    name="ediUsername"
                                    label="EDI Username"
                                    value={settings.ediUsername}
                                    onChange={handleChange}
                                />


                                <TextField
                                    className={classes.input}
                                    name="ediPassword"
                                    label="EDI Password"
                                    value={settings.ediPassword}
                                    onChange={handleChange}
                                    type="password"
                                />



                                <TextField
                                    className={classes.input}
                                    name="pgpMifirKey"
                                    label="PGP MiFIR Key"
                                    value={settings.pgpMifirKey}
                                    multiline
                                    onChange={handleChange}
                                />


                            </Container>


                        </>
                    )}


                    <Typography variant="h6" className={classes.title}>Debug Proxy</Typography>
                    <Container component={Paper} maxWidth="lg" className={`${classes.paddingTop} ${classes.paddingBottom}`}>

                        <FormControl className={classes.input}>
                            <InputLabel id="debugProxyEnabledLabel">Enable debug proxy</InputLabel>
                            <Select
                                labelId="debugProxyEnabledLabel"
                                value={settings.debugProxyEnabled}
                                name="debugProxyEnabled"
                                onChange={handleChange}
                            >
                                <MenuItem value={true}>Yes</MenuItem>
                                <MenuItem value={false}>No</MenuItem>
                            </Select>

                        </FormControl>


                        <FormControl className={classes.input}>
                            <InputLabel id="debugProxyProtocolLabel">Debug proxy protocol</InputLabel>
                            <Select
                                labelId="debugProxyProtocolLabel"
                                value={settings.debugProxyProtocol}
                                name="debugProxyProtocol"
                                onChange={handleChange}
                            >
                                <MenuItem value="http">http</MenuItem>
                                <MenuItem value="https">https</MenuItem>
                            </Select>
                        </FormControl>

                        <TextField
                            className={classes.input}
                            name="debugProxyHostname"
                            label="Debug proxy hostname"
                            value={settings.debugProxyHostname}
                            onChange={handleChange}
                            type="text"
                        />

                        <TextField
                            className={classes.input}
                            name="debugProxyPort"
                            label="Debug proxy port"
                            value={settings.debugProxyPort}
                            type="number"
                            onChange={handleChange}
                        />

                    </Container>

                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                        className={classes.button}
                        startIcon={<SendIcon />}
                    >&nbsp;Save</Button>

                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => {
                            history.goBack();
                        }}
                        className={classes.button}
                        startIcon={<CancelIcon />}
                    >&nbsp;Cancel</Button>


                </form>
            }
        </>
    );
};

export default Settings;