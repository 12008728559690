import { Avatar, Button, CircularProgress, Divider, IconButton, LinearProgress, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import SendIcon from '@material-ui/icons/Send';
import Alert from '@material-ui/lab/Alert';
import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus as codeStyle } from 'react-syntax-highlighter/dist/esm/styles/prism';
import useApi from '../hooks/useApi';
import mifirStyles from '../utils/styles';
import TransactionDetails from './TransactionDetails';
import TransactionService from './TransactionService';



const TransactionPreview = ({ id, setOpen }) => {

    const [isLoading, data] = useApi(`/api/transaction/build-transaction/${id}`);
    const [isSending, setIsSending] = React.useState(false);
    const [sendingDisabled, setSendingDisabled] = React.useState(false);

    const deliverTransaction = async () => {
        setIsSending(true);
        setSendingDisabled(true);
        await TransactionService.deliverTransaction(id).catch((error) => {
            setIsSending(false);
            alert("a technical error occured while sending transaction: " + error);
        });
        history.go(0);
    }

    const classes = mifirStyles();
    const history = useHistory();
    return (
        <>
            {isLoading ?
                <CircularProgress />
                : <div>
                    <div className={classes.modalHeader}>
                        <IconButton onClick={() => setOpen(false)}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                    <Typography variant="h5">Send Transaction Report</Typography>

                    <Typography className={classes.header} variant="body1">Validation:</Typography>

                    {data && data.xml ?
                        <Alert variant="filled" severity="success" className={classes.spacingBottom}>Validation successfull</Alert>
                        :
                        <Alert variant="filled" severity="error" className={classes.spacingBottom}>Error while serializing transaction!</Alert>
                    }

                    {data && data.xml &&
                        <>


                            <TransactionDetails transaction={data.transaction} className={classes.paddingTop}/>

                            <Typography className={classes.header} variant="body1">Preview Transaction XML</Typography>
                            <SyntaxHighlighter className={classes.xmlCode} language="xml" style={codeStyle}>
                                {data.xml}
                            </SyntaxHighlighter>

                        </>
                    }

                    {data && !data.valid &&
                        <List >
                            {data.validationErrors.map(validationError =>
                                <div key={validationError.field}>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar className={classes.errorAvatar}><PriorityHighIcon /></Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={`Field: ${validationError.field}`}
                                            secondary={
                                                <Typography variant="body2">{validationError.error}</Typography>
                                            }
                                        />
                                    </ListItem>
                                    <Divider variant="inset" component="li" />
                                </div>
                            )}

                        </List>
                    }

                    {isSending &&
                        <LinearProgress style={{ marginTop: "20px" }} />
                    }

                    <Button
                        disabled={!data || !data.valid || sendingDisabled}
                        className={classes.actionButton}
                        variant="contained"
                        color="primary"
                        onClick={deliverTransaction}
                        startIcon={<SendIcon />}>Send</Button>



                    <Button
                        className={classes.actionButton}
                        variant="contained"
                        color="secondary"
                        startIcon={<EditIcon />}
                        onClick={() => {
                            history.push(`/transaction/${id}`);
                        }}
                    >
                        Edit
                    </Button>




                </div>}



        </>
    );
};

export default TransactionPreview;

TransactionPreview.propTypes = {
    id: PropTypes.string,
    setOpen: PropTypes.func
}