import { Collapse, DialogContentText, TableContainer, TableHead, TablePagination, Tooltip } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Fab from "@material-ui/core/Fab";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { Alert } from "@material-ui/lab";
import PropTypes from 'prop-types';
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useRecoilState } from "recoil";
import {
    tradingPartyPage,
    tradingPartyPageSize,
    tradingPartySortOrder,
    tradingPartySortProperty
} from "../atoms/app-atoms";
import LoadingIndicator from "../components/LoadingIndicator";
import usePageApi from "../hooks/usePageApi";
import mifirStyles from "../utils/styles";
import TradingPartyService from "./TradingPartyService";
import { tradingPartyTypeLabel } from "./TradingPartyType";


const tradingPartyService = TradingPartyService.getInstance();

const TradingPartyList = () => {

    const history = useHistory();

    const [page, setPage] = useRecoilState(tradingPartyPage);
    const [rowsPerPage, setRowsPerPage] = useRecoilState(tradingPartyPageSize);
    const [sortProperty] = useRecoilState(tradingPartySortProperty);
    const [sortOrder] = useRecoilState(tradingPartySortOrder);

    const [loading, rows, totalCount, errorOpen, errorMessage] = usePageApi('/api/trading-party/page', page, rowsPerPage, sortOrder, sortProperty);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(event.target.value);
    }

    const handleAddTradingParty = () => {
        history.push('/trading-party/new');
    }

    const classes = mifirStyles();

    if (loading) return (<LoadingIndicator />);
    else return (
        <>
            <Typography className={classes.header} variant="h5">Trading Parties</Typography>
            <Collapse in={errorOpen} className={classes.alertCollapse}>
                <Alert color="error">{errorMessage}</Alert>
            </Collapse>
            <TableContainer component={Paper}>
                <Table>
                    <TradingPartyTableHeader />
                    <TradingPartyTableBody rows={rows} />
                </Table>
                <Tooltip title="New"><Fab className={classes.fab} color="primary" aria-label="add" onClick={handleAddTradingParty}><AddIcon /></Fab></Tooltip>
            </TableContainer>

            <TablePagination
                rowsPerPageOptions={[10, 20, 50]}
                component="div"
                count={parseInt(totalCount)}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </>
    );
}


const TradingPartyTableHeader = () => {

    const classes = mifirStyles();
    const [sortOrder, setSortOrder] = useRecoilState(tradingPartySortOrder);
    const [sortProperty, setSortProperty] = useRecoilState(tradingPartySortProperty);

    const createSortHandler = (property) => {
        const isAsc = sortProperty === property && sortOrder === 'asc';
        setSortOrder(isAsc ? 'desc' : 'asc');
        setSortProperty(property);
    }

    const tableHeaders = [
        { key: 'name', label: 'Name' },
        { key: 'type', label: 'Type' },
        { key: 'identifier', label: 'Identifier' }
    ];

    return (
        <>
            <TableHead>
                <TableRow>
                    {tableHeaders.map(field =>
                        <TableCell className={classes.tableHeader} key={field.key}
                            sortDirection={field.key === sortProperty ? sortOrder : false}>
                            <TableSortLabel
                                active={field.key === sortProperty}
                                direction={field.key === sortProperty ? sortOrder : 'asc'}
                                onClick={() => createSortHandler(field.key)}
                            >
                                {field.label}
                            </TableSortLabel>
                        </TableCell>
                    )}
                    <TableCell className={classes.tableHeader} align="right">Actions</TableCell>
                </TableRow>
            </TableHead>
        </>
    );
}

const TradingPartyTableBody = (props) => {

    const history = useHistory();

    const [deleteName, setDeleteName] = useState('');
    const [deletionId, setDeletionId] = useState(0);
    const [deletionDialogOpen, setDeletionDialogOpen] = useState(false);

    const deleteTradingParty = (id, name) => {
        setDeleteName(name);
        setDeletionId(id);
        setDeletionDialogOpen(true);
    }

    const handleDeletionDialogClose = (answer) => {

        setDeletionDialogOpen(false);

        if (answer) {
            tradingPartyService.deleteTradingParty(deletionId).then(response => {
                if (response.data === true) {
                    history.go(0);
                }
            }).catch(error => {
                console.error(error.response.data.message);
            });
        }

    }


    React.useEffect(() => {
    }, [props])

    return (
        <>
            <TableBody>
                {props.rows && props.rows.map(row =>
                    <TableRow key={row.id}>
                        <TableCell>{row.name}</TableCell>
                        <TableCell>{tradingPartyTypeLabel[row.type]}</TableCell>
                        <TableCell>{row.identifier}</TableCell>
                        <TableCell align="right">
                            <Tooltip title="Edit">
                                <IconButton onClick={() => history.push(`/trading-party/${row.id}`)}>
                                    <EditIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Delete">
                                <IconButton onClick={() => deleteTradingParty(row.id, row.name)}>
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                        </TableCell>
                    </TableRow>
                )}
            </TableBody>
            <Dialog
                open={deletionDialogOpen}
            >
                <DialogTitle>Deletion confirmation</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Do you really want to delete Trading Party {deleteName}?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleDeletionDialogClose(false)} color="primary" autoFocus>No</Button>
                    <Button onClick={() => handleDeletionDialogClose(true)} color="primary">Yes</Button>
                </DialogActions>
            </Dialog>
        </>
    );

}

export default TradingPartyList;

TradingPartyTableBody.propTypes = {
    rows: PropTypes.array
}