import React from 'react';
import { useHistory, Route } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { userProfile } from "../atoms/app-atoms";
import AuthService from "./AuthService";
import PropTypes from 'prop-types';

const PrivateRoute = ({ children, ...rest }) => {

    const [profile, setProfile] = useRecoilState(userProfile);
    const history = useHistory();

    return (
        <Route
            {...rest}
            render={() => {

                if (profile && profile.id) return children;

                else {
                     AuthService.checkIfLoggedIn().then(response => {
                         setProfile(response.data);
                     }).catch( () => {
                         history.push('/login');
                     });
                }

            }}
        />
    );

}

export default PrivateRoute;

PrivateRoute.propTypes = {
    children: PropTypes.oneOfType([PropTypes.array,PropTypes.object])
}