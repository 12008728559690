import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Collapse from "@material-ui/core/Collapse";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import CancelIcon from '@material-ui/icons/Cancel';
import LockIcon from '@material-ui/icons/Lock';
import SaveIcon from '@material-ui/icons/Save';
import Alert from "@material-ui/lab/Alert";
import { authenticator } from 'otplib';
import PropTypes from 'prop-types';
import qrcode from 'qrcode';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import { userProfile } from "../atoms/app-atoms";
import AuthService from '../Auth/AuthService';
import ConfirmationDialog from "../components/ConfirmationDialog";
import TokenAuthInput from '../components/TokenAuthInput';
import mifirStyles from "../utils/styles";
import { UserRole } from "./UserRole";
import UserService from "./UserService";

const UserEdit = (props) => {

    const history = useHistory();

    const { userId } = useParams();

    const [profile, setProfile] = useRecoilState(userProfile);
    const [errorMessage, setErrorMessage] = useState(null);
    const [isError, setIsError] = useState(false);
    const [editingProfile, setEditingProfile] = useState({});
    const [qrCodeUrl, setQrCodeUrl]  = useState(null);

    const tokenRef = useRef();

    useEffect(() => {

        const loadUser = (id) => {
            UserService.getUser(id).then(response => {
                setEditingProfile({ ...response.data })
            }).catch(error => {
                console.log(error);
            });
        };

        if (props.isNew) {
            setEditingProfile({});
        } else if (parseInt(userId) && profile.id !== parseInt(userId)) {
            loadUser(parseInt(userId));
        } else setEditingProfile({ ...profile });

    }, [profile, setEditingProfile, props, userId]);


    const handleSubmit = (event) => {
        event.preventDefault();
        console.log(profile);

        if (editingProfile.newPassword && editingProfile.newPassword !== editingProfile.passwordVerification) {
            console.log("OK ERROR NOW!");
            setErrorMessage("Password and password verification do not match!");
            setIsError(true);
            return;
        }

        let profileCopy = { ...editingProfile };
        profileCopy['passwordVerification'] = undefined;

        if (props.isNew) {
            profileCopy.password = profileCopy.newPassword;
            profileCopy.newPassword = undefined;

            UserService.createUser(profileCopy).then(() => {
                history.go(-1);
            }).catch(error => {
                setErrorMessage(error.response.data.message);
                setIsError(true);
            })
        } else {
            UserService.updateUser(profileCopy).then(response => {
                if (profile.id === response.data.id) setProfile(response.data);
                history.go(-1);
            }).catch(error => {
                setErrorMessage(error.response.data.message);
                setIsError(true);
            })
        }
    };

    const handleChange = (event) => {
        const updateVal = {};
        updateVal[event.target.name] = event.target.value;
        setEditingProfile({ ...editingProfile, ...updateVal });
    }

    const onFocus = (event) => {
        if (event.target.autocomplete) event.target.autocomplete = "new-password";
    };

    const generateTokenSecret = async () => {
        console.log("generating now");
        const generateTokenSecretResponse = await UserService.generateTokenSecret();
        const tokenData = generateTokenSecretResponse.data;
        const otpauth = authenticator.keyuri(tokenData.email, tokenData.systemName, tokenData.tokenSecret);
        setEditingProfile({...editingProfile, tokenSecret: tokenData.tokenSecret});

        qrcode.toDataURL(otpauth, { margin: 0 },(err, imageUrl) => {
            if(err) {
                alert(`Could not generate QR Code: ${err}`);
                return;
            }
            setQrCodeUrl(imageUrl);

        });

    }

    const verifyToken = async (token) => {
        const response = await UserService.verifySetupToken(token).catch(error => {
            alert(`could not verify token: ${error}`);
            return;
        });
        if(response.data) {
            AuthService.logout();
            history.push("/login");
        }
        else {
            tokenRef.current.clearInput();
            setErrorMessage("The token is invalid");
            setIsError(true);
            setTimeout(() => setIsError(false), 5000);
        }
    }

    const remove2FaConfirmation = useRef();

    const reset2Fa = async () => {
        remove2FaConfirmation.current.show();
    }

    const handleRemove2FaAnswer = async (answer) => {
        if(answer === true) {
            const user = await AuthService.remove2FaConfirmation();
            setEditingProfile({...editingProfile, tokenAuthActive: user.tokenAuthActive });
        }
    }

    const classes = mifirStyles();
    return (
        <Container className={classes.root}>
            <Typography className={classes.heading} variant="h5">Edit User</Typography>
            <form noValidate autoComplete="false" onSubmit={handleSubmit}>
                <Container className={classes.formContainer} component={Paper} maxWidth="lg">

                    <TextField
                        onFocus={onFocus}
                        className={classes.input}
                        value={editingProfile.firstname || ''}
                        name="firstname"
                        onChange={handleChange}
                        label="Firstname"
                    />
                    <TextField
                        onFocus={onFocus}
                        className={classes.input}
                        value={editingProfile.lastname || ''}
                        name="lastname"
                        onChange={handleChange}
                        label="Lastname"
                    />

                    {profile.userRole === UserRole.ADMIN &&
                    <TextField
                        onFocus={onFocus}
                        className={classes.input}
                        value={editingProfile.email || ''}
                        name="email"
                        onChange={handleChange}
                        label="E-Mail"
                    />
                    }


                </Container>

                <Typography className={classes.heading} variant="h5">Password</Typography>
                <Container className={classes.formContainer} component={Paper} maxWidth="lg">

                    {profile.userRole !== UserRole.ADMIN &&
                    <TextField
                        className={classes.input}
                        value={editingProfile.password || ''}
                        name="password"
                        onChange={handleChange}
                        label="Current password"
                        type="password"
                        onFocus={onFocus}
                    />
                    }

                    <TextField
                        className={classes.input}
                        value={editingProfile.newPassword || ''}
                        name="newPassword"
                        onChange={handleChange}
                        label="New Password"
                        type="password"
                        onFocus={onFocus}
                        autoComplete="new-passwords"
                    />

                    <TextField
                        className={classes.input}
                        value={editingProfile.passwordVerification || ''}
                        name="passwordVerification"
                        onChange={handleChange}
                        label="Password verification"
                        onFocus={onFocus}
                        type="password"
                        autoComplete="new-password"
                    />

                </Container>

                <Typography variant="h5" className={classes.heading}>Two Factor Authentication</Typography>
                <Container maxWidth="lg" component={Paper} className={classes.formContainer}>
                    {editingProfile.tokenAuthActive &&
                        <div>
                            <Alert variant="standard" color="success" className={classes.twoFaActiveMessage}>
                            <Typography variant="body1">Two factor authentication is active.</Typography>
                            </Alert>
                            
                            <Button
                                variant="contained"
                                onClick={reset2Fa}
                            >Reset Token Info</Button>
                        </div >

                    }

                    {(!editingProfile.tokenSecret || !editingProfile.tokenAuthActive) &&
                        <Button
                            startIcon={<LockIcon/>}
                            onClick={generateTokenSecret}
                            color="default"
                            variant="contained"
                        >Enable 2FA</Button>
                    }

                    {qrCodeUrl &&
                        <Container className={classes.qrCodeContainer}>
                            
                            <img alt="Authenticator QRCode" src={qrCodeUrl} />
                            <TokenAuthInput
                                onChange={verifyToken}
                                name="confirmToken"
                                helpText="Scan QR Code with your authenticator app and confirm with token"
                                ref={tokenRef}
                            />
                        </Container>
                    }

                    <ConfirmationDialog
                        title="Remove 2FA"
                        question="Do you really want to remove two factor authentication?"
                        onClose={handleRemove2FaAnswer}
                        ref={remove2FaConfirmation}
                    />

                </Container>
                
                <Collapse in={isError}>
                    <Alert severity="error" onClose={() => {
                        setIsError(false);
                        setErrorMessage('');
                    }}>{errorMessage}</Alert>
                </Collapse>

                <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    onClick={handleSubmit}
                    className={classes.button}
                ><SaveIcon fontSize="small"/>&nbsp;Save</Button>
                
                <Button
                    color="secondary"
                    className={classes.button}
                    variant="contained"
                    onClick={() => history.go(-1)}
                ><CancelIcon fontSize="small"/>&nbsp;Cancel</Button>

            </form>
        </Container>
    );
}

export default UserEdit;

UserEdit.propTypes = {
    isNew: PropTypes.bool
}