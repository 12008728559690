
import AuthService from '../Auth/AuthService';
import Axios from "axios";

class TradingPartyService {

    static myInstance = null;
    static getInstance = () => {
        if(TradingPartyService.myInstance == null) {
            TradingPartyService.myInstance = new TradingPartyService();
        }
        return this.myInstance;
    }

    getTradingPartyPage = async (pageNumber, pageSize, sortProperty, sortOrder) => {
        return Axios.request({
            url: '/api/trading-party/page',
            method: 'get',
            params: {pageNumber, pageSize, sortProperty, sortOrder},
            headers: {
                ...AuthService.getAuthHeader()
            }
        });
    }

    getTradingPartyById = async (id) => {
        return Axios.request({
            url: `/api/trading-party/${id}`,
            method: 'get',
            headers: {
                ...AuthService.getAuthHeader()
            }
        });
    }

    createTradingParty = async (tradingParty) => {
        return Axios({
            url: '/api/trading-party',
            method: 'post',
            data: {...tradingParty },
            headers: {
                ...AuthService.getAuthHeader()
            }
        });
    }

    updateTradingParty = async (id, tradingParty) => {
        return Axios({
            url: `/api/trading-party/${id}`,
            method: 'put',
            data: {...tradingParty},
            headers: {
                ...AuthService.getAuthHeader()
            }
        });
    }

    getTradingParties = async () => {
        return Axios.request({
            url: '/api/trading-party',
            method: 'get',
            headers: {
                ...AuthService.getAuthHeader()
            }
        });
    }

    deleteTradingParty = async (id) => {
        return Axios.request({
            url: `/api/trading-party/${id}`,
            method: 'delete',
            headers: {
                ...AuthService.getAuthHeader()
            }
        });
    }

}

export default TradingPartyService;
