import { Button, Container, Grid, TextField, Typography } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import PropTypes from 'prop-types';
import React, { forwardRef, useImperativeHandle } from 'react';
import mifirStyles from '../utils/styles';


const intialFields = {
    'first': '',
    'second': '',
    'third': '',
    'fourth': '',
    'fifth': '',
    'sixth': ''
};


const TokenAuthInput = forwardRef(({ onChange, name, helpText, showSubmitButton = true }, ref) => {

    useImperativeHandle(
        ref,
        () => ({
            clearInput() {
                setDigits({...intialFields});
            },
            setFocus() {
                digitRefs.first.focus();
            }
        })
    );

    const [digits, setDigits] = React.useState({...intialFields});
    const digitRefs = [];

    const getNextElement = (digitName) => {
        let count = 0;
        let nextElement;
        Object.keys(digits).map(currentDigit => {
            if (currentDigit === digitName) nextElement = count + 1;
            count++
        });
        if (nextElement > 5) return null;
        else return Object.keys(digits)[nextElement];

    }


    const digitChanged = (event) => {
        if (event.target.value.length > 1) return;
        const digitName = event.target.name.replace(`${name}_`, '');
        const newDigits = { ...digits, [digitName]: event.target.value };
        setDigits(newDigits);
        const nextElement = getNextElement(digitName);
        if (nextElement) document.getElementById(`${name}_${nextElement}`).focus();
        else if(showSubmitButton) document.getElementById(`${name}_submit`).focus();

        if(!showSubmitButton) submitToken(newDigits);
    }


    const submitToken = (newDigits = null) => {

        const digitsToSubmit = newDigits ? newDigits : digits;
        let tokenString = '';
        Object.keys(digitsToSubmit).map(digitName => {
            tokenString += digitsToSubmit[digitName];
        })
        onChange(tokenString);
    }

    const classes = mifirStyles();
    return (
        <Container className={`${classes.noPaddingX} ${classes.spacingTop}`}>
            {helpText &&
                <Typography variant="body1">{helpText}</Typography>
            }
            <Grid container>

                {Object.keys(digits).map(digit =>
                    <span key={`${name}_${digit}`}>
                        <TextField
                            inputRef={ref => digitRefs[digit] = ref}
                            name={`${name}_${digit}`}
                            id={`${name}_${digit}`}
                            variant="outlined"
                            value={digits[digit]}
                            type="number"
                            onFocus={event => event.target.select()}
                            onInput={digitChanged}
                            className={classes.tokenInput}
                            inputProps={{min: 0, style: { textAlign: 'center' }}}
                        />
                    </span>
                )}
            </Grid>

            {showSubmitButton &&
                <Grid container item>
                    <Button
                        id={`${name}_submit`}
                        onClick={() => submitToken(null)}
                        startIcon={<SendIcon />}
                        variant="contained"
                        color="primary"
                        className={classes.singleButton}
                    >Submit OTP</Button>
                </Grid>
            }
        </Container>
    );
});

TokenAuthInput.propTypes = {
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    helpText: PropTypes.string,
    showSubmitButton: PropTypes.bool
}

export default TokenAuthInput;