import AppBar from "@material-ui/core/AppBar";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { AccountCircle } from "@material-ui/icons";
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import GroupIcon from '@material-ui/icons/Group';
import ListIcon from "@material-ui/icons/List";
import MenuIcon from '@material-ui/icons/Menu';
import SettingsIcon from '@material-ui/icons/Settings';
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useRecoilState } from "recoil";
import { userProfile } from "./atoms/app-atoms";
import AuthService from "./Auth/AuthService";
import { UserRole } from "./User/UserRole";
import mifirStyles from "./utils/styles";



const Heading = () => {

    const history = useHistory();

    const [anchorEl, setAnchorEl] = useState(null);
    const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [profile, setProfile] = useRecoilState(userProfile);

    const classes = mifirStyles();

    const handleUserMenu = (event) => {
        setAnchorEl(event.target);
        setIsUserMenuOpen(true);
    };

    const handleUserMenuClose = () => {
        setAnchorEl(null);
        setIsUserMenuOpen(false);
    }

    const handleDrawerClose = () => {
        setIsDrawerOpen(false);
    }

    const handleLogout = () => {
        AuthService.logout();
        setIsUserMenuOpen(false);
        setProfile(null);
        history.push('/login');
    }

    const renderUserMenu = (
        <Menu
            anchorEl={anchorEl}
            keepMounted
            open={isUserMenuOpen}
            onClose={handleUserMenuClose}
            transformOrigin={{vertical:'top', horizontal: 'right'}}
        >
            <List>
                <ListItem button onClick={() => {history.push("/user"); setIsUserMenuOpen(false); }}>
                    <ListItemIcon><SettingsIcon/></ListItemIcon>
                    <ListItemText>User Settings</ListItemText>
                </ListItem>
                <Divider/>
                <ListItem button onClick={handleLogout}>
                    <ListItemIcon><ExitToAppIcon/></ListItemIcon>
                    <ListItemText>Logout</ListItemText>
                </ListItem>
            </List>
        </Menu>
    );

    const renderDrawerMenu = (
        <Drawer anchor="left" open={isDrawerOpen} onClose={handleDrawerClose} className={classes.drawer}>
            <div className={classes.toolbar}>
                <Typography variant="h6" className={classes.appLogo}>MiFIR Reporting</Typography>
            </div>
            <Divider/>
            <List>
                <ListItem button key="transactionList" onClick={() => {history.push("/"); setIsDrawerOpen(false) }}>
                    <ListItemIcon><ListIcon/></ListItemIcon>
                    <ListItemText>Transaction List</ListItemText>
                </ListItem>

                <ListItem button key="tradingPartyList" onClick={() => { history.push('/trading-party'); setIsDrawerOpen(false) }}>
                    <ListItemIcon><AccountBalanceIcon/></ListItemIcon>
                    <ListItemText>Trading Parties</ListItemText>
                </ListItem>

                { profile && profile.userRole === UserRole.ADMIN &&
                    <>
                        <Divider/>
                        <ListItem button key="userList" onClick={() => { history.push("/users"); setIsDrawerOpen(false) }}>
                            <ListItemIcon><GroupIcon/></ListItemIcon>
                            <ListItemText>User Administration</ListItemText>
                        </ListItem>

                        <ListItem button key="settings" onClick={() => { history.push("/settings"); setIsDrawerOpen(false) }}>
                            <ListItemIcon><SettingsIcon/></ListItemIcon>
                            <ListItemText>Settings</ListItemText>
                        </ListItem>
                    </>
                }
            </List>
        </Drawer>
    );

    const handleDrawer = () => {
        setIsDrawerOpen(true);
    }

    return(
        <AppBar postion="static">
            <Toolbar>
                {profile && profile.id && <IconButton
                    edge={"start"}
                    className={classes.menuButton}
                    color={"inherit"}
                    aria-label={"menu"}
                    onClick={handleDrawer}
                >
                    <MenuIcon/>
                </IconButton>}
                <Typography variant="body1">MiFIR Reporting</Typography>
                {profile && profile.id && (
                    <div className={classes.avatar}>
                        <IconButton
                            edge="end"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleUserMenu}
                            color="inherit">
                            <AccountCircle />
                            <Typography variant="caption">&nbsp;{`${profile.firstname} ${profile.lastname}`}</Typography>
                        </IconButton>
                    </div>
                )}
            </Toolbar>
            {renderDrawerMenu}
            {renderUserMenu}
        </AppBar>
    );
};

export default Heading;
