import Axios from 'axios';
import AuthService from "../Auth/AuthService";


class UserService {

    static createUser = async (user) => {
        return Axios.request({
            url: `/api/user`,
            method: 'post',
            data: user,
            headers: {
                ...AuthService.getAuthHeader()
            }
        });
    }

    static updateUser = async (user) => {
        return Axios.request({
            url: `/api/user/${user.id}`,
            method: 'put',
            data: user,
            headers: {
                ...AuthService.getAuthHeader()
            }
        });
    }

    static getUserPage = async (pageNumber, pageSize, sortOrder, sortProperty) => {
        return Axios.request({
            url: `/api/user`,
            method: 'get',
            params: { pageNumber, pageSize, sortOrder, sortProperty },
            headers: {
                ...AuthService.getAuthHeader()
            }
        });
    }

    static getUser = async (id) => {
        return Axios.request({
            url: `/api/user/${id}`,
            method: 'get',
            headers: {
                ...AuthService.getAuthHeader()
            }
        });
    }

    static generateTokenSecret = async () => {
        return Axios.request({
            url: '/api/auth/generate-token-secret',
            method: 'get',
            headers: {
                ...AuthService.getAuthHeader()
            }
        });
    }

    static verifySetupToken = async (token) => {
        return Axios.request({
            url: '/api/auth/verify-setup-token',
            method: 'post',
            headers: {
                ...AuthService.getAuthHeader()
            },
            data: { token }
        });
    }

    static verfifyLoginToken = async (token)  => {
        return Axios.request({
            url: '/api/auth/verify-login-token',
            method: 'post',
            headers: {
                ...AuthService.getAuthHeader()
            },
            data: { token }
        });
    }


}

export default UserService;
