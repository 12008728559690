
export const formatNumber = (number) => {

    return Intl.NumberFormat('de-DE').format(number);
}

export const formatPrice = (price, currency, transaction) => {

    let options = undefined;
    if (currency) options = { currency, style: 'currency' };

    if (!transaction || !transaction.isDebt) {
        return Intl.NumberFormat('de-DE', options).format(price)
    }
    else if (transaction && transaction.isDebt) {
        return Intl.NumberFormat('de-DE').format(price) + '%';
    }
    else {
        return Intl.NumberFormat('de-DE').format(price);
    }

}

export const formatQuantity = (quantity, currency, transaction) => {

    let options = undefined;
    if (currency && transaction && transaction.isDebt) options = { currency, style: 'currency' };

    if (transaction.isDebt) {
        const val = Intl.NumberFormat('de-DE', options).format(quantity);
        return val;
    }
    else {
        return Intl.NumberFormat('de-DE').format(quantity);
    }
}


