import { FormControl, InputLabel, MenuItem, Select, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Collapse from "@material-ui/core/Collapse";
import Container from "@material-ui/core/Container";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Paper from "@material-ui/core/Paper";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import TextField from "@material-ui/core/TextField";
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import { Autocomplete } from "@material-ui/lab";
import Alert from "@material-ui/lab/Alert";
import { countries } from 'countries-list';
import dateformat from 'dateformat';
import PropTypes from 'prop-types';
import React, { forwardRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import mifirStyles from '../utils/styles';
import TradingParty from './TradingParty';
import TradingPartyService from "./TradingPartyService";
import { NationalIdType, TradingPartyPropsType, TradingPartyType, tradingPartyTypeLabel } from "./TradingPartyType";


const tradingPartyService = TradingPartyService.getInstance();
const countryArray = [];
for (let countryCode of Object.keys(countries)) {
    countryArray.push({
        value: countryCode,
        name: countries[countryCode].name
    });
}


const TradingPartyEdit = forwardRef((props, ref) => {

    const history = useHistory();
    const { tradingPartyId } = useParams();

    const [tradingParty, setTradingParty] = useState({
        name: '',
        identifier: '',
        type: TradingPartyType.LEI,
        country: 'AT',
        nationalIdType: 'CONCAT',
        firstName: '',
        lastName: '',
        firms: [],
        birthdate: null,

    });
    const [isNameValid, setIsNameValid] = useState(true);
    const [isIdentifierValid, setIsIdentifierValid] = useState(true);

    const [isFirstNameValid, setIsFirstNameValid] = useState(true);
    const [firstNameHelperText, setFirstNameHelperText] = useState('');
    const [isLastNameValid, setIsLastNameValid] = useState(true);
    const [lastNameHelperText, setLastNameHelperText] = useState('');

    const [isBirthdateValid, setIsBirthdateValid] = useState(true);
    const [birthdateHelperText, setBirthdateHelperText] = useState('');

    const [nameHelperText, setNameHelperText] = useState('');
    const [identifierHelperText, setIdentifierHelperText] = useState('');

    const [isError, setIsError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const [isCountryValid, setIsCountryValid] = useState(true);
    const [countryHelperText, setCountryHelperText] = useState('');

    React.useEffect(() => {

        const loadTradingParty = (id) => {
            tradingPartyService.getTradingPartyById(id).then(response => {
                setTradingParty(response.data);
            }).catch(error => {
                if (error.response) {
                    setErrorMessage(error.response.data.message);
                    setIsError(true);
                } else console.error(error);
            });
        }

        if (props.tradingParty) setTradingParty(props.tradingParty);
        else if (parseInt(tradingPartyId)) loadTradingParty(parseInt(tradingPartyId));
    }, [props, tradingPartyId]);

    const validateIdentifier = () => {
        console.log(typeof tradingParty.type);
        console.log(typeof TradingPartyType.NATIONAL_ID);

        console.log(tradingParty.type);
        console.log(TradingPartyType.NATIONAL_ID);

        console.log(tradingParty.type === TradingPartyType.NATIONAL_ID);


        if (tradingParty.type === TradingPartyType.LEI) {
            if (!tradingParty.identifier) {
                setIsIdentifierValid(false);
                setIdentifierHelperText('An LEI Code is required for a legal entity');
                return false;
            }
            else if (!tradingParty.identifier.match(/^[A-Z0-9]{18,18}[0-9]{2,2}$/)) {
                setIsIdentifierValid(false);
                setIdentifierHelperText('This is not a valid LEI. LEI must be 18 alphanumeric chars followed by two numeric.');
                return false;

            } else {
                setIsIdentifierValid(true);
                return true;

            }
        }

        else if (tradingParty.type === TradingPartyType.NATIONAL_ID) {
            if (!tradingParty.identifier) {
                setIsIdentifierValid(false);
                setIdentifierHelperText('An identifier is required');
                return false;
            }
            else if (!tradingParty.identifier.match(/^(([A-Z]{2,2}[A-Z0-9]{1,33})|([A-Z]{2,2}[0-9]{8}[A-Z]{1}[A-Z#]{4}[A-Z]{1}[A-Z#]{4})|(FI[A-Z0-9\-+]{1,33})|(LV[A-Z0-9-]{1,33}))$/)) {
                setIsIdentifierValid(false);
                setIdentifierHelperText('This is not a valid person identifier');
                return false;
            }
            else {
                setIsIdentifierValid(true);
                return true;
            }
        }

        else {
            if (!tradingParty.identifier) {
                setIsIdentifierValid(false);
                setIdentifierHelperText('An identifier is required');
                return false;
            } else {
                setIsIdentifierValid(true);
                setIdentifierHelperText('');
                return true;
            }
        }



    }

    const resetFormValidation = () => {
        setIsNameValid(true);
        setNameHelperText('');
        setIsIdentifierValid(true);
        setIdentifierHelperText('');
        setIsFirstNameValid(true);
        setFirstNameHelperText('');
        setIsLastNameValid(true);
        setLastNameHelperText('');
        setIsBirthdateValid(true);
        setBirthdateHelperText('');

    }

    const validate = () => {
        let formIsValid = true;
        resetFormValidation();


        if (!tradingParty.name) {
            setIsNameValid(false);
            setNameHelperText('A name is required')
            formIsValid = false;
        }

        if (tradingParty.type !== TradingPartyType.MIC) {
            if (!tradingParty.country) {
                setIsCountryValid(false);
                setCountryHelperText('A country is required');
                formIsValid = false;
            }
        }

        if (tradingParty.type === TradingPartyType.NATIONAL_ID) {

            if (!tradingParty.firstName) {
                setIsFirstNameValid(false);
                setFirstNameHelperText('In non legal entities a first name is required');
                formIsValid = false;
            }

            if (!tradingParty.lastName) {
                setIsLastNameValid(false);
                setLastNameHelperText('In non legal entities a last name is required');
                formIsValid = false;
            }

            if (!tradingParty.birthdate) {
                setIsBirthdateValid(false);
                setBirthdateHelperText('In non legal entities a birthdate is required');
                formIsValid = false;
            }

        }

        if (!validateIdentifier()) formIsValid = false;

        return formIsValid;

    }

    const handleCancel = () => {
        if (!props.isDialog) history.go(-1);
        else props.dialogClose();
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        if (!validate()) return;

        if (!tradingParty.id) {
            tradingPartyService.createTradingParty(tradingParty).then(() => {
                if (!props.isDialog) history.go(-1);
                else props.dialogClose();
            }).catch(error => {
                setErrorMessage(error.response.data.message);
                setIsError(true);
            })
        } else {
            tradingPartyService.updateTradingParty(tradingParty.id, tradingParty).then(() => {
                if (!props.isDialog) history.go(-1);
                else props.dialogClose();
            }).catch(error => {
                if (error.response) {
                    setErrorMessage(error.response.data.message);
                    setIsError(true);
                } else console.error(error);
            })
        }
    }

    const handleChange = (event) => {
        const change = {};

        if (tradingParty.type !== TradingPartyType.NATIONAL_ID) {
            tradingParty.birthdate = null;
        }

        change[event.target.name] = event.target.value;
        setTradingParty({ ...tradingParty, ...change });
    }

    const getIdentifierLabel = () => {
        switch (tradingParty.type) {
            case TradingPartyType.LEI:
                return 'LEI Code';
            case TradingPartyType.MIC:
                return 'MIC Code';
            case TradingPartyType.NATIONAL_ID:
                return NationalIdType[tradingParty.nationalIdType] || NationalIdType['CONCAT'];
            default:
                return 'Identifier';
        }
    }

    const classes = mifirStyles();

    const notLegalEntityForm = (
        <>
            <Typography className={classes.header} variant="h5">Non legal entity fields</Typography>
            <Container ref={ref} component={Paper} className={classes.formContainer}>
                <TextField
                    className={classes.textField}
                    value={tradingParty.firstName || ''}
                    label="First Name"
                    onChange={handleChange}
                    name="firstName"
                    helperText={firstNameHelperText}
                    error={!isFirstNameValid}
                />
                <TextField
                    className={classes.textField}
                    value={tradingParty.lastName || ''}
                    label="Last Name"
                    onChange={handleChange}
                    name="lastName"
                    helperText={lastNameHelperText}
                    error={!isLastNameValid}
                />

                <TextField
                    className={classes.textField}
                    value={tradingParty.birthdate !== '0000-00-00' ? tradingParty.birthdate : dateformat(new Date(), 'yyyy') + '-01-01'}
                    onSelect={handleChange}
                    onChange={handleChange}
                    label="Date of birth"
                    type="date"
                    name="birthdate"
                    InputLabelProps={{ shrink: true }}
                    helperText={birthdateHelperText}
                    error={!isBirthdateValid}
                />

                <TradingParty
                    value={tradingParty.firms}
                    onChange={handleChange}
                    name="firms"
                    label="Firms"
                    typeFilter={[TradingPartyType.LEI]}
                    multiple
                />

            </Container>
        </>
    );




    return (
        <>
            <Typography className={classes.header} variant="h5">Trading Party</Typography>
            <Collapse in={isError}>
                <Alert onClose={() => setIsError(false)} color="error">{errorMessage}</Alert>
            </Collapse>
            <form onSubmit={handleSubmit} noValidate>
                <Container className={classes.formContainer} component={Paper} maxWidth="lg">

                    <TextField
                        error={!isNameValid}
                        helperText={nameHelperText}
                        className={classes.textField}
                        value={tradingParty.name || ''}
                        onChange={handleChange}
                        label="Name"
                        name="name"
                    />

                    <FormLabel className={classes.formLabel} component="legend">Type of Entity</FormLabel>
                    <RadioGroup
                        className={classes.textField}
                        row
                        name="type"
                        onChange={handleChange}
                        value={tradingParty.type || TradingPartyType.LEI}
                    >
                        <FormControlLabel
                            value={TradingPartyType.LEI}
                            control={<Radio color="primary" />}
                            label={tradingPartyTypeLabel[TradingPartyType.LEI]}
                            labelPlacement="end"
                        />
                        <FormControlLabel
                            value={TradingPartyType.NATIONAL_ID}
                            control={<Radio color="primary" />}
                            label={tradingPartyTypeLabel[TradingPartyType.NATIONAL_ID]}
                            labelPlacement="end"
                        />
                    </RadioGroup>



                    {(tradingParty.type !== TradingPartyType.MIC) &&
                        <Autocomplete
                            className={classes.textField}
                            name="country"
                            value={{ ...countryArray.find(country => country.value === tradingParty.country) }}
                            options={countryArray}
                            getOptionLabel={option => `${option.name} (${option.value})`}
                            getOptionSelected={(option, test) => option.value === test.value}
                            onChange={(event, option) => {
                                setTradingParty({ ...tradingParty, ...{ country: option.value } });
                            }}
                            renderInput={(params) => <TextField
                                helperText={countryHelperText}
                                error={!isCountryValid}
                                label="Country"
                                margin="normal"
                                {...params}
                            />}
                        />
                    }

                    {tradingParty.type === TradingPartyType.NATIONAL_ID &&
                        <FormControl className={classes.textField}>
                            <InputLabel>National ID Type</InputLabel>
                            <Select
                                name="nationalIdType"
                                onChange={handleChange}
                                value={tradingParty.nationalIdType || 'CONCAT'}
                            >
                                {Object.keys(NationalIdType).map(type =>
                                    <MenuItem key={type} value={type}>{NationalIdType[type]}</MenuItem>
                                )}
                            </Select>
                        </FormControl>

                    }

                    <TextField
                        error={!isIdentifierValid}
                        helperText={identifierHelperText}
                        className={classes.textField}
                        value={tradingParty.identifier || ''}
                        label={getIdentifierLabel()}
                        onChange={handleChange}
                        name="identifier"
                    />

                </Container>


                {tradingParty.type === TradingPartyType.NATIONAL_ID && notLegalEntityForm}

                <Button className={classes.button} variant="contained" color="primary" type="submit"
                    onClick={handleSubmit}><SaveIcon fontSize="small" />&nbsp;Save</Button>
                <Button className={classes.button} variant="contained" color="secondary"
                    onClick={handleCancel}><CancelIcon />&nbsp;Cancel</Button>
            </form>

        </>
    );
});

TradingPartyEdit.propTypes = {
    isDialog: PropTypes.bool,
    tradingParty: PropTypes.shape(TradingPartyPropsType),
    dialogClose: PropTypes.func
}


export default TradingPartyEdit;
