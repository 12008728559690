import { TableHead, TableSortLabel, Tooltip } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Fab from "@material-ui/core/Fab";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { useRecoilState } from "recoil";
import {
    userListPage,
    userListPageSize,
    userListSortOrder,
    userListSortProperty,
    userListTotalCount
} from "../atoms/app-atoms";
import mifirStyles from '../utils/styles';
import UserService from "./UserService";

const UserList = () => {

    const history = useHistory();

    const [userPage, setUserPage] = useState([]);
    const [pageNumber, setPageNumber] = useRecoilState(userListPage);
    const [pageSize, setPageSize] = useRecoilState(userListPageSize);
    const [sortOrder, setSortOrder] = useRecoilState(userListSortOrder);
    const [sortProperty, setSortProperty] = useRecoilState(userListSortProperty);
    const [totalCount, setTotalCount] = useRecoilState(userListTotalCount);

    const classes = mifirStyles();

    useEffect(() => {
        UserService.getUserPage(pageNumber, pageSize, sortOrder, sortProperty).then(response => {
            setUserPage(response.data.content);
            setTotalCount(response.data.totalElements);
        }).catch(error => {
            console.error(error);
        })
    }, [pageNumber, pageSize, sortOrder, sortProperty, setTotalCount]);

    const handleChangePage = (event, newPageNumber) => {
        setPageNumber(newPageNumber);
    }

    const handleChangePageSize = (event) => {
        setPageSize(event.target.value);
    }

    const handleAddUser = () => {
        history.push("/user/new");
    }

    const createSortHandler = (property) => {
        const isAsc = sortProperty === property && sortOrder === 'asc';
        setSortOrder(isAsc ? 'desc' : 'asc');
        setSortProperty(property);
    }

    const userTableHeaderFields = [
        {
            key: 'firstname',
            label: 'Firstname'
        },
        {
            key: 'lastname',
            label: 'Lastname'
        },
        {
            key: 'email',
            label: 'E-Mail'
        }
    ];

    const UserTableHeader = (
        <TableHead>
            <TableRow>
                {userTableHeaderFields.map(field =>
                    <TableCell className={classes.tableHeader} key={field.key}
                        sortDirection={field.key === sortProperty ? sortOrder : false}>
                        <TableSortLabel
                            active={field.key === sortProperty}
                            direction={field.key === sortProperty ? sortOrder : 'asc'}
                            onClick={() => createSortHandler(field.key)}
                        >
                            {field.label}
                        </TableSortLabel>
                    </TableCell>
                )}
                <TableCell align="right" className={classes.tableHeader}>Action</TableCell>
            </TableRow>
        </TableHead>

    );

    const UserTableBody = (
        <TableBody>
            {userPage.map(row =>
                <TableRow key={row.id}>
                    <TableCell>{row.firstname}</TableCell>
                    <TableCell>{row.lastname}</TableCell>
                    <TableCell>{row.email}</TableCell>
                    <TableCell align="right">
                        <Tooltip title="Edit">
                            <IconButton size="small" onClick={() => history.push(`/user/${row.id}`)}><EditIcon /></IconButton>
                        </Tooltip>
                    </TableCell>
                </TableRow>
            )}
        </TableBody>
    )

    return (
        <>
            <Typography className={classes.header} variant="h5">User List</Typography>
            <Container component={Paper} maxWidth="lg">
                <Table>
                    {UserTableHeader}
                    {UserTableBody}
                </Table>
            </Container>
            <TablePagination
                rowsPerPageOptions={[10, 20, 50]}
                component="div"
                count={parseInt(totalCount)}
                rowsPerPage={pageSize}
                page={pageNumber}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangePageSize}
            />
            <Tooltip title="New">
                <Fab className={classes.fab} color="primary" aria-label="add" onClick={handleAddUser}><AddIcon /></Fab>
            </Tooltip>
        </>
    );
}

export default UserList;
