import { Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import LoadingIndicator from '../components/LoadingIndicator';
import useApi from '../hooks/useApi';
import mifirStyles from '../utils/styles';
import ReportDetails from './ReportDetails';

const TransactionShowReports = ({ id }) => {

    const [isLoading, reports] = useApi(`/api/report/getReportsByTransactionId/${id}`);
    const classes = mifirStyles();
    if (isLoading) return <LoadingIndicator />
    else return (
        <>
            <Grid container style={{alignItems: 'flex-end'}}>
                <Grid item><Typography className={classes.reportsHeader} variant="h6">Reports for Transaction </Typography></Grid>
                <Grid item><Typography className={classes.info} variant="body2">({id})</Typography></Grid>
            </Grid>

            <div>
                {reports && reports.map(report =>
                    <ReportDetails key={report.id} {...report}/>
                )}
            </div>
        </>

    );
};

export default TransactionShowReports;

TransactionShowReports.propTypes = {
    id: PropTypes.string
}