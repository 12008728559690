import { Accordion, AccordionDetails, AccordionSummary, Avatar, Container, Divider, Grid, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import dateformat from 'dateformat';
import PropTypes from 'prop-types';
import React from 'react';
import ResponseStatusIcon from '../components/ResponseStatusIcon';
import mifirStyles from '../utils/styles';
import { ReportResponseStatus } from './ReportResponseStatus';

const ReportResponse = ({ responses }) => {

    const classes = mifirStyles();
    return (
        <>
            <Container className={classes.spacingBottom}>
                <Divider className={classes.dividerWithMargin} />
                <Typography variant="h6" className={classes.header}>Responses</Typography>

                {responses.map(response =>
                    <Accordion key={response.id}>

                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Grid container className={classes.topAccordion}>
                                <Grid item>
                                    <ResponseStatusIcon status={response.status} />
                                </Grid>
                                <Grid item>
                                    <Typography variant="body1">{ReportResponseStatus[response.status]} on {dateformat(response.received)}</Typography>
                                </Grid>
                            </Grid>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Container className={classes.noPaddingX}>
                                <Divider />

                                {response?.reportResponseErrors?.length>0 &&
                                    <>
                                        <Typography variant="h6" className={classes.header}>Errors</Typography>
                                        <List>
                                            {response.reportResponseErrors.map(error =>
                                                <React.Fragment key={error.id}>
                                                    <ListItem className={classes.noPaddingX}>
                                                        <ListItemAvatar>
                                                            <Avatar className={classes.errorAvatar}><PriorityHighIcon /></Avatar>
                                                        </ListItemAvatar>
                                                        <ListItemText
                                                            primary={`Code: ${error.code}`}
                                                            secondary={
                                                                <Typography variant="body2">{error.description}</Typography>
                                                            }
                                                        />
                                                    </ListItem>
                                                </React.Fragment>
                                            )}
                                        </List>
                                    </>
                                }
                                {!response?.reportResponseErrors?.length &&
                                    <Typography variant="body2" style={{ color: '#c4c4c4' }}>No errors</Typography>
                                }
                            </Container>
                        </AccordionDetails>
                    </Accordion>
                )}

            </Container>


        </>
    );

}

ReportResponse.propTypes = {
    responses: PropTypes.array.isRequired
}

export default ReportResponse;