import { atom } from 'recoil';

/*
 * App values
 */
export const userProfile = atom({key: 'userProfile', default: {  }});

/*
 * transaction list atoms
 */
export const transactionListPage = atom({key: 'transactionListPage', default: 0});
export const transactionPageSize = atom({key: 'transactionPageSize', default: 10});
export const transactionListSortProperty = atom( {key: 'transactionListSortProperty', default: 'tradingDateTime'});
export const transactionListSortOrder = atom({key: 'transactionListSortOrder', default: 'desc'});
export const transactionListSearch = atom({key: 'transactionListSearch', default: ''});

/*
 * user list atoms
 */
export const userListPage = atom({key: 'userListPage', default: 0});
export const userListPageSize = atom({key: 'userListPageSize', default: 10});
export const userListSortOrder = atom({key: 'userListSortOrder', default: 'asc'});
export const userListSortProperty = atom({key: 'userListSortProperty', default: 'id'});
export const userListTotalCount = atom({key: 'userListTotalCount', default: 0});

/*
 * trading party list atoms
 */
export const tradingPartyPage = atom({key: 'tradingPartyPage', default: 0});
export const tradingPartyPageSize = atom({key: 'tradingPartyPageSize', default: 10});
export const tradingPartySortOrder = atom({key: 'tradingPartySortOrder', default: 'asc'});
export const tradingPartySortProperty = atom({key: 'tradingPartySortProperty', default: 'id'});
export const tradingPartyTotalCount = atom({key: 'tradingPartyTotalCount', default: 0});
