import { Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Collapse from "@material-ui/core/Collapse";
import Container from "@material-ui/core/Container";
import InputAdornment from "@material-ui/core/InputAdornment";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import EmailIcon from '@material-ui/icons/Email';
import SendIcon from '@material-ui/icons/Send';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import Alert from "@material-ui/lab/Alert";
import React, { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useRecoilState } from "recoil";
import { userProfile } from "../atoms/app-atoms";
import TokenAuthInput from "../components/TokenAuthInput";
import mifirStyles from "../utils/styles";
import AuthService from "./AuthService";

const Login = () => {

    const history = useHistory();

    const classes = mifirStyles();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorOpen, setErrorOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [profile, setProfile] = useRecoilState(userProfile);
    const [need2faToken, setNeed2FaToken] = useState(false);
    const [authToken, setAuthToken] = useState('');

    const tokenRef = useRef();

    const login = async () => {
        const response = await AuthService.checkIfLoggedIn();
        setProfile(response.data);
        history.push("/");
    }

    React.useEffect(() => {

        if (AuthService.hasToken() && (!profile || !profile.id)) {
            login().then();
        }

    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const loginResponse = await AuthService.login(email, password, authToken).catch(error => {
            setAlertMessage(error.response.data.message);
            setErrorOpen(true);
            clearForm();
        });

        // if no login response just do nothing
        if (!loginResponse) { return; }

        // if token is wrong show error and clear token input
        else if (loginResponse.data["token_wrong"]) {
            tokenRef.current.clearInput();
            setAlertMessage("Invalid token")
            setErrorOpen(true);
            setTimeout(() => setErrorOpen(false), 5000);
        }

        // show token input if token is needed. do not try to login.
        else if (loginResponse.data["need_2fa_token"]) {
            setNeed2FaToken(true);
            tokenRef.current.setFocus();
            return;
        }


        AuthService.setAuthToken(loginResponse.data.access_token);
        await login();

    };

    const clearForm = () => {
        setEmail('');
        setPassword('');
    }


    return (
        <Container maxWidth={"sm"} component={Paper} className={classes.loginComponent}>
            <Typography variant="h4">Login</Typography>
            <form className={classes.form} onSubmit={handleSubmit}>

                <TextField
                    id={"email"}
                    label={"Email"}
                    className={classes.text}
                    onChange={e => setEmail(e.target.value)}
                    value={email}
                    InputProps={{
                        endAdornment: <InputAdornment position={"end"}><EmailIcon /></InputAdornment>
                    }}
                    disabled={need2faToken}
                />

                <TextField
                    id={"password"}
                    label={"Password"}
                    type={"password"}
                    className={classes.text}
                    onChange={e => setPassword(e.target.value)}
                    value={password}
                    InputProps={{
                        endAdornment: <InputAdornment position={"end"}><VpnKeyIcon /></InputAdornment>
                    }}
                    disabled={need2faToken}
                />

                {need2faToken &&
                    <Grid container item className={classes.tokenAuth}>
                        <TokenAuthInput
                            name="loginToken"
                            onChange={setAuthToken}
                            showSubmitButton={false}
                            helpText="Authentication token"
                            className={classes.spacingTop}
                            ref={tokenRef}
                        />
                    </Grid>
                }

                <Collapse in={errorOpen}>
                    <Alert severity="error">{alertMessage}</Alert>
                </Collapse>

                <Button type="submit" variant={"contained"} color={"primary"} className={classes.button}><SendIcon
                    fontSize='small' />&nbsp;Login</Button>

            </form>
        </Container>

    );
}

export default Login;
