import { Accordion, AccordionDetails, AccordionSummary, Avatar, Button, CircularProgress, Divider, Grid, Typography } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import GetApp from '@material-ui/icons/GetApp';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import dateformat from 'dateformat';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus as codeStyle } from 'react-syntax-highlighter/dist/esm/styles/prism';
import xmlFormatter from 'xml-formatter';
import mifirStyles from '../utils/styles';
import ReportResponses from './ReportResponses';
import TransactionDetails from './TransactionDetails';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import TransactionService from './TransactionService';



const ReportDetails = ({ id, error, transaction, xml, date, processingError, action, reportResponses, responseStatus }) => {

    const [isGeneratingPdfReport, setIsGeneratingPdfReport] = useState(false);

    const generatePdfReport = async () => {
        setIsGeneratingPdfReport(true);
        const response = await TransactionService.generatePdfReport(id);
        try {
            if (response.status === 200) {
                setIsGeneratingPdfReport(false);


                const file = new Blob([response.data], { type: 'application/pdf' });
                const fileUrl = URL.createObjectURL(file);

                window.open(fileUrl);
            }
        } catch (error) {
            alert(`${error}`);
            setIsGeneratingPdfReport(false);
            console.error(error);
        }

    }

    const transactionObject = JSON.parse(transaction);
    const classes = mifirStyles();
    return (

        <Accordion className={classes.reportCard}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Grid container className={classes.topAccordion}>
                    <Grid>
                        <Avatar style={{ marginRight: '20px' }} className={error ? classes.error : action === "CANCEL" ? classes.warning : responseStatus === "PDNG" ? classes.warning : classes.success}>
                            {error ? <PriorityHighIcon /> : action === "CANCEL" ? <CloseIcon /> : responseStatus === "PDNG" ? <HourglassEmptyIcon /> : <CheckIcon />}
                        </Avatar>
                    </Grid>
                    <Grid item><Typography variant="body1">Report {action === "NEW" ? "sent" : action === "CANCEL" ? "canceled" : "unknown"} on {dateformat(new Date(date))}</Typography></Grid>
                </Grid>
            </AccordionSummary>

            <AccordionDetails style={{ flexDirection: 'column' }}>

                <Divider className={classes.dividerWithMargin} />

                {transactionObject &&
                    <>
                        <TransactionDetails transaction={transactionObject} />
                        <Typography variant="h6" className={classes.tradingDetailsTitle}>Export Report</Typography>
                        <div>
                            <Button onClick={() => { generatePdfReport(); }} variant="contained" color="default" size="small" className={classes.marginBottom}><GetApp /> Download PDF Report</Button>
                            {isGeneratingPdfReport && <CircularProgress size={24} className={classes.marginLeft} />}
                        </div>

                    </>
                }
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>Reported Content</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <SyntaxHighlighter className={classes.xmlCode} language="xml" style={codeStyle}>
                            {xmlFormatter(xml, {
                                indentation: '   ',
                                collapseContent: true,
                            })}
                        </SyntaxHighlighter>
                    </AccordionDetails>
                </Accordion>

                <Accordion disabled={!error || !processingError}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}><Typography>Technical Errors</Typography></AccordionSummary>
                    <AccordionDetails>
                        {processingError &&
                            <SyntaxHighlighter className={classes.xmlCode} language="json" style={codeStyle}>
                                {JSON.stringify(JSON.parse(processingError), null, 3)}
                            </SyntaxHighlighter>
                        }
                    </AccordionDetails>
                </Accordion>
            </AccordionDetails>

            <ReportResponses responses={reportResponses} />

        </Accordion>
    );
};
export default ReportDetails;

ReportDetails.propTypes = {
    id: PropTypes.number.isRequired,
    action: PropTypes.string.isRequired,
    error: PropTypes.bool.isRequired,
    transaction: PropTypes.string.isRequired,
    xml: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    processingError: PropTypes.string,
    reportResponses: PropTypes.array,
    responseStatus: PropTypes.string

};