
import { Avatar } from '@material-ui/core';
import { string } from 'prop-types';
import React from 'react'
import mifirStyles from '../utils/styles';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';


const ResponseStatusIcon = ({status}) => {

    const classes = mifirStyles();
    return(
        <div style={{marginRight: '20px'}}>
            {(status === 'ACPT' || status === 'ACPD') &&
                <Avatar className={classes.success}><CheckIcon/></Avatar>
            }
            {(status === 'RJPD' || status === 'RJCT') &&
                <Avatar className={classes.error}><PriorityHighIcon/></Avatar>
            }
            {status === 'WARN' &&
                <Avatar className={classes.warning}><CloseIcon/></Avatar>
            }
            {(status === 'RCVD' || status === 'PDNG') &&
                <Avatar className={classes.success}><HourglassEmptyIcon/></Avatar>
            }

        </div>
    )

}

ResponseStatusIcon.propTypes = {
    status: string.isRequired
}

export default ResponseStatusIcon;
