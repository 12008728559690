import Axios from "axios";
import AuthService from "../Auth/AuthService";

export class SettingsService {

    static async getSetting(setting) {
        return Axios.request({
            url: `/api/settings/${setting}`,
            method: 'get',
            headers: {
                ...AuthService.getAuthHeader()
            }
        });
    }


    static async getSettings() {
        return Axios.request({
            url: "/api/settings",
            method: 'get',
            headers: {
                ...AuthService.getAuthHeader()
            }
        })
    }

    static async saveSettings(settings) {

        const settingsList = [];
        Object.keys(settings).map(key => settingsList.push({key, value: settings[key]}));

        return Axios.request({
            url: "/api/settings",
            method: 'post',
            headers: {
                ...AuthService.getAuthHeader()
            },
            data: settingsList
        });

    }



}
