import { Button, Divider, Grid, LinearProgress, Modal, TableSortLabel, Tooltip } from "@material-ui/core";
import Collapse from "@material-ui/core/Collapse";
import Container from "@material-ui/core/Container";
import Fab from "@material-ui/core/Fab";
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import CancelScheduleSendIcon from '@material-ui/icons/CancelScheduleSend';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from "@material-ui/icons/Clear";
import EditIcon from '@material-ui/icons/Edit';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import FlareIcon from '@material-ui/icons/Flare';
import HistoryIcon from '@material-ui/icons/History';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import SearchIcon from '@material-ui/icons/Search';
import SendIcon from '@material-ui/icons/Send';
import DeleteIcon from '@material-ui/icons/Delete';
import UndoIcon from '@material-ui/icons/Undo';
import CancelIcon from '@material-ui/icons/Cancel';
import Alert from "@material-ui/lab/Alert";
import dateformat from 'dateformat';
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useRecoilState } from "recoil";
import {
    transactionListPage,
    transactionListSearch,
    transactionListSortOrder,
    transactionListSortProperty,
    transactionPageSize
} from "../atoms/app-atoms";
import LoadingIndicator from "../components/LoadingIndicator";
import usePageApi from "../hooks/usePageApi";
import { formatPrice, formatQuantity } from "../utils/Formatter";
import mifirStyles from "../utils/styles";
import TransactionCancellation from "./TransactionCancellation";
import TransactionPreview from "./TransactionPreview";
import TransactionShowReports from "./TransactionShowReports";
import { TransactionReportStatus } from './TransactionType';
import TransactionDetails from "./TransactionDetails";
import TransactionService from "./TransactionService";




const TransactionList = () => {

    const history = useHistory();
    const [rowsPerPage, setRowsPerPage] = useRecoilState(transactionPageSize);
    const [page, setPage] = useRecoilState(transactionListPage);
    const [sortOrder] = useRecoilState(transactionListSortOrder);
    const [sortProperty] = useRecoilState(transactionListSortProperty);
    const [search, setSearch] = useRecoilState(transactionListSearch);
    const [searchString, setSearchString] = useState('');
    const [searchTimer, setSearchTimer] = useState(null);
    const [isPreviewTransactionXmlOpen, setIsPreviewTransactionXmlOpen] = useState(false);
    const [previewTransactionId, setPreviewTransactionId] = useState(null);
    const [isShowReportsOpen, setIsShowReportsOpen] = useState(false);
    const [showReportsTransactionId, setShowReportsTransactionId] = useState(null);
    const [isCancelTransactionOpen, setIsCancelTransactionOpen] = useState(false);
    const [cancelTransactionId, setCancelTransactionId] = useState(null);
    const [archiveTransactionObject, setArchiveTransactionObject] = useState(null);
    const [isArchiveTransactionOpen, setArchiveTransactionOpen] = useState(false);
    const [isArchiving, setIsArchiving] = useState(false);
    const [archive, setArchive] = useState(false);
    const [refresh, setRefresh] = useState(Math.random());


    const [loading, rows, totalCount, errorOpen, errorMessage] = usePageApi('/api/transaction', page, rowsPerPage, sortOrder, sortProperty, searchString, archive, refresh);

    let searchInputRef;


    const handleChangePage = async (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(event.target.value);
        setPage(0);
    };


    const handleAddTransaction = () => {
        history.push('/transaction/new');
    };

    const handleSearch = (event) => {
        event.preventDefault();
    };

    const handleChange = (event) => {
        const search = event.target.value
        setSearch(search);
        if (searchTimer) clearTimeout(searchTimer);
        setSearchTimer(setTimeout(() => {
            setSearchString(search);
        }, 1000));
    };

    const resetSearch = () => {
        searchInputRef.value = '';
        handleChange({ target: searchInputRef });
    }

    const handleModalClose = () => {
        setIsPreviewTransactionXmlOpen(false);
        setPreviewTransactionId(null);
    }

    const handleShowReportsClose = () => {
        setIsShowReportsOpen(false);
        setShowReportsTransactionId(null);
    }

    const handleCancelModalClose = () => {
        setIsCancelTransactionOpen(false);
        setCancelTransactionId(null);
    }

    const archiveTransaction = async (transaction) => {
        setArchiveTransactionObject(transaction);
        setArchiveTransactionOpen(true);
    }


    const classes = mifirStyles();

    const handleArchiveModalClose = () => {
        setArchiveTransactionObject(null);
        setArchiveTransactionOpen(false);
    }

    const sendArchiveTransaction = async (transaction) => {
        console.log(transaction);
        setIsArchiving(true);
        const response = await TransactionService.submitTransaction({ ...transaction, archived: !transaction.archived });
        if (response.status === 200) {
            setRefresh(Math.random());
            setIsArchiving(false);
            handleArchiveModalClose();
        }
        else {
            setIsArchiving(false);
            handleArchiveModalClose();
            alert(response.data);
        }
    }

    const ArchiveTransactionModal = (
        <Modal
            open={isArchiveTransactionOpen}
            onClose={() => handleArchiveModalClose()}
        >
            {archiveTransactionObject ? <Container className={classes.modalStyle} component={Paper}>
                <div className={classes.modalHeader}>
                    <IconButton onClick={() => handleArchiveModalClose()}>
                        <CloseIcon />
                    </IconButton>
                </div>
                <Typography variant="h5" className={classes.paddingBottom}>{archiveTransactionObject.archived ? 'Restore' : 'Delete'} Transaction.</Typography>
                <Typography variant="body2" className={classes.paddingBottom}>
                    Do you really want to {archiveTransactionObject.archived ? 'restore' : 'delete'} this transaction ({archiveTransactionObject.id})?
                </Typography>
                {archiveTransactionObject && <TransactionDetails transaction={archiveTransactionObject} />}
                <Divider />

                {isArchiving && <LinearProgress style={{ marginTop: "20px" }} />}

                <Button

                    className={classes.actionButton}
                    variant="contained"
                    color="secondary"
                    onClick={() => sendArchiveTransaction(archiveTransactionObject)}
                    startIcon={archiveTransactionObject.archived ? <UndoIcon /> : <DeleteIcon />}>
                    Yes, {archiveTransactionObject.archived ? 'restore' : 'delete'} transaction
                </Button>
                <Button

                    className={classes.actionButton}
                    variant="contained"
                    color="primary"
                    onClick={() => handleArchiveModalClose()}
                    startIcon={<CancelIcon />}
                >
                    No, Abort
                </Button>
            </Container> : <div>loading...</div>
            }
        </Modal >
    );


    const TransactionPreviewModal = (
        <Modal
            open={isPreviewTransactionXmlOpen}
            onClose={handleModalClose}
        >
            <Container className={classes.modalStyle} component={Paper}>
                <TransactionPreview id={previewTransactionId} setOpen={setIsPreviewTransactionXmlOpen} />
            </Container>
        </Modal>
    );

    const TransactionCancellationModal = (
        <Modal
            open={isCancelTransactionOpen}
            onClose={handleCancelModalClose}
        >
            <Container className={classes.modalStyle} component={Paper}>
                <TransactionCancellation id={cancelTransactionId} setOpen={setIsCancelTransactionOpen} />
            </Container>
        </Modal>
    );

    const ShowReportsModal = (
        <Modal
            open={isShowReportsOpen}
            onClose={handleShowReportsClose}
        >
            <Container className={classes.modalStyle} component={Paper}>
                <TransactionShowReports id={showReportsTransactionId} />
            </Container>
        </Modal>
    );


    if (loading) return (<LoadingIndicator />);
    else return (
        <>
            <Container maxWidth="xl" className={classes.spacingBottom}>
                <div style={{ display: 'none' }}>{refresh}</div>
                <Typography className={classes.header} variant="h5">Transaction {archive ? 'Archive' : 'List'}</Typography>
                <form className={classes.searchForm} noValidate onSubmit={handleSearch}>
                    <FormControl>
                        <InputLabel htmlFor="search-input">Search</InputLabel>
                        <Input
                            inputRef={(ref) => searchInputRef = ref}
                            id="search-input"
                            name="search"
                            onChange={handleChange}
                            value={search || ''}
                            startAdornment={
                                <InputAdornment position="start"><SearchIcon /></InputAdornment>
                            }
                            endAdornment={
                                <IconButton onClick={resetSearch}
                                    style={{ visibility: searchString.length ? 'visible' : 'hidden' }}>
                                    <ClearIcon />
                                </IconButton>
                            }

                        />
                    </FormControl>
                </form>
            </Container>
            <Container maxWidth="xl">
                <TableContainer component={Paper}>
                    <Collapse in={errorOpen} className={classes.alertCollapse}>
                        <Alert color="error">{errorMessage}</Alert>
                    </Collapse>
                    <Table>
                        <TransactionTableHeader />
                        <TransactionTableBody
                            rows={rows}
                            setPreviewTransactionId={setPreviewTransactionId}
                            setIsPreviewTransactionXmlOpen={setIsPreviewTransactionXmlOpen}
                            setIsShowReportsOpen={setIsShowReportsOpen}
                            setShowReportsTransactionId={setShowReportsTransactionId}
                            setIsCancelTransactionOpen={setIsCancelTransactionOpen}
                            setCancelTransactionId={setCancelTransactionId}
                            archiveTransaction={archiveTransaction}
                        />
                    </Table>
                </TableContainer>
                <Grid container spacing={2} >
                    <Grid item style={{ display: 'flex', flexGrow: 1, justifyContent: "start", marginTop: '5px' }}>
                        <GotoArchive showingArchive={archive} setArchive={setArchive} />
                    </Grid>
                    <Grid item style={{ display: 'flex', flexGrow: 1, justifyContent: "end" }}>
                        <TablePagination
                            rowsPerPageOptions={[10, 20, 50]}
                            component="div"
                            count={parseInt(totalCount)}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Grid>
                </Grid>
            </Container>


            <Tooltip title="New"><Fab className={classes.fab} color="primary" aria-label="add" onClick={handleAddTransaction}><AddIcon /></Fab></Tooltip>

            {TransactionPreviewModal}
            {TransactionCancellationModal}
            {ShowReportsModal}
            {ArchiveTransactionModal}
        </>
    );
}


const GotoArchive = ({ showingArchive, setArchive }) => {
    return (
        <>
            <Button size="small" onClick={() => {
                setArchive(!showingArchive);
            }}>{showingArchive ? 'Show active' : 'Show archive'}</Button>
        </>
    );

}

GotoArchive.propTypes = {
    showingArchive: PropTypes.bool.isRequired,
    setArchive: PropTypes.func.isRequired,
}

const TransactionTableHeader = () => {

    const [sortOrder, setSortOrder] = useRecoilState(transactionListSortOrder);
    const [sortProperty, setSortProperty] = useRecoilState(transactionListSortProperty)

    const tableHeader = [
        { key: 'reportStatus', label: 'Status' },
        { key: 'isin', label: 'ISIN' },
        { key: 'buyers', label: 'Buyers', doNotSort: true },
        { key: 'sellers', label: 'Sellers', doNotSort: true },
        { key: 'quantity', label: 'Quantity / Nominal' },
        { key: 'price', label: 'Price / Percentage' },
        { key: 'tradingDateTime', label: 'Date/Time' },
    ];

    const classes = mifirStyles();

    const createSortHandler = (property) => {
        const isAsc = sortProperty === property && sortOrder === 'asc';
        setSortOrder(isAsc ? 'desc' : 'asc');
        setSortProperty(property);
    }

    return (
        <TableHead>
            <TableRow>
                {tableHeader.map(head =>
                    <TableCell
                        className={classes.tableHeader}
                        key={head.key}
                        align={"left"}
                        padding={"normal"}
                        sortDirection={sortProperty === head.key ? sortOrder : false}

                    >
                        {!head.doNotSort &&
                            <TableSortLabel
                                active={sortProperty === head.key}
                                direction={sortProperty === head.key ? sortOrder : 'asc'}
                                onClick={() => createSortHandler(head.key)}
                            >
                                {head.label}
                            </TableSortLabel>
                        }
                        {head.doNotSort &&
                            <>{head.label}</>
                        }

                    </TableCell>
                )}
                <TableCell
                    className={classes.tableHeader}
                    align="right"
                    padding="normal"
                >Actions</TableCell>
            </TableRow>
        </TableHead>
    );
}

const reportStatusIcon = (reportStatus) => {
    switch (reportStatus) {
        case TransactionReportStatus.NEW:
            return <Tooltip title="New"><FlareIcon /></Tooltip>;
        case TransactionReportStatus.SENT:
            return <Tooltip title="Wating for reply"><HourglassEmptyIcon /></Tooltip>;
        case TransactionReportStatus.ACCEPTED:
            return <Tooltip title="Accepted"><CheckIcon /></Tooltip>;
        case TransactionReportStatus.CANCELED:
            return <Tooltip title="Canceled"><CancelOutlinedIcon /></Tooltip>;
        case TransactionReportStatus.ERROR:
            return <Tooltip title="Error"><ErrorOutlineIcon /></Tooltip>;
        default:
            return <FlareIcon />;
    }
}

const TransactionTableBody = (props) => {

    const history = useHistory();


    return (
        <TableBody>
            {props.rows.map(row =>
                <TableRow key={row.id}>
                    <TableCell align="left">{reportStatusIcon(row.reportStatus)}</TableCell>
                    <TableCell align="left">{row.isin || '-'}</TableCell>
                    <TableCell align="left">{row.buyers.map(buyer => buyer.name).join(', ') || '-'}</TableCell>
                    <TableCell align="left">{row.sellers.map(seller => seller.name).join(', ') || '-'}</TableCell>
                    <TableCell align="left">
                        <Tooltip title={(row && row.isDebt) ? 'Nominal' : 'Quantity'}>
                            <span>{(row.quantity && row.quantity != 0) ? formatQuantity(row.quantity, row.currency, row) : '-'}</span>
                        </Tooltip>
                        {row.isDebt && row.netAmount &&
                            <Tooltip title="Net Amount">
                                <span>
                                    <Typography variant="body2" style={{ fontSize: '12px' }}>({formatQuantity(row.netAmount, row.currency, row)})</Typography>
                                </span>
                            </Tooltip>
                        }
                    </TableCell>
                    <TableCell align="left">{row.price ? formatPrice(row.price, row.currency, row) : '-'}</TableCell>
                    <TableCell align="left">{
                        new Date(row.tradingDateTime).toString() !== 'Invalid Date' ? dateformat(new Date(row.tradingDateTime), 'dd.mm.yyyy HH:MM:ss') : '-'
                    }
                    </TableCell>

                    <TableCell align="right">
                        <nobr>

                            {(
                                row.reportStatus === TransactionReportStatus.NEW ||
                                row.reportStatus === TransactionReportStatus.CANCELED ||
                                row.reportStatus === TransactionReportStatus.ERROR
                            ) && !row.archived &&
                                <Tooltip title="Edit transaction">
                                    <IconButton size="small" onClick={() => history.push(`/transaction/${row.id}`)}>
                                        <EditIcon size="small" />
                                    </IconButton>
                                </Tooltip>
                            }

                            {(row.reportStatus === TransactionReportStatus.NEW || row.reportStatus === TransactionReportStatus.CANCELED || row.reportStatus === TransactionReportStatus.ERROR) && !row.archived &&
                                <Tooltip title="Delete Transaction">
                                    <IconButton size="small" onClick={() => {
                                        props.archiveTransaction(row)
                                    }}>
                                        <DeleteIcon size="small" />
                                    </IconButton>
                                </Tooltip>
                            }

                            {row.archived &&
                                <Tooltip title="Restore Transaction">
                                    <IconButton size="small" onClick={() => {
                                        props.archiveTransaction(row)
                                    }}>
                                        <UndoIcon size="small" />
                                    </IconButton>
                                </Tooltip>
                            }

                            {(
                                row.reportStatus === TransactionReportStatus.NEW ||
                                row.reportStatus === TransactionReportStatus.CANCELED ||
                                row.reportStatus === TransactionReportStatus.ERROR
                            ) && !row.archived &&
                                <Tooltip title="Send report">
                                    <IconButton
                                        size="small"
                                        onClick={() => {
                                            props.setPreviewTransactionId(row.id);
                                            props.setIsPreviewTransactionXmlOpen(true);
                                        }
                                        }>
                                        <SendIcon />
                                    </IconButton>
                                </Tooltip>
                            }

                            {row.reportStatus === TransactionReportStatus.ACCEPTED && !row.archived &&
                                <Tooltip title="Cancel sent report">
                                    <IconButton
                                        size="small"
                                        onClick={() => {
                                            props.setCancelTransactionId(row.id);
                                            props.setIsCancelTransactionOpen(true);
                                        }
                                        }>
                                        <CancelScheduleSendIcon />
                                    </IconButton>
                                </Tooltip>
                            }



                            {row.reportStatus !== TransactionReportStatus.NEW &&
                                <Tooltip title="Show history">
                                    <IconButton size="small" onClick={() => {
                                        props.setShowReportsTransactionId(row.id);
                                        props.setIsShowReportsOpen(true);
                                    }}>
                                        <HistoryIcon size="small" />
                                    </IconButton>
                                </Tooltip>
                            }


                        </nobr>
                    </TableCell>
                </TableRow>
            )
            }
        </TableBody >
    );
}


export default TransactionList;


TransactionTableBody.propTypes = {
    setPreviewTransactionId: PropTypes.func.isRequired,
    setIsPreviewTransactionXmlOpen: PropTypes.func.isRequired,
    setShowReportsTransactionId: PropTypes.func.isRequired,
    setIsShowReportsOpen: PropTypes.func.isRequired,
    setCancelTransactionId: PropTypes.func.isRequired,
    setIsCancelTransactionOpen: PropTypes.func.isRequired,
    archiveTransaction: PropTypes.func.isRequired,
    rows: PropTypes.array.isRequired
}