import { InputAdornment, Tooltip } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import IconButton from "@material-ui/core/IconButton";
import Modal from "@material-ui/core/Modal";
import Paper from "@material-ui/core/Paper";
import TextField from '@material-ui/core/TextField';
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from '@material-ui/icons/Edit';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PropTypes from "prop-types";
import React from 'react';
import mifirStyles from "../utils/styles";
import TradingPartyEdit from "./TradingPartyEdit";
import TradingPartyService from "./TradingPartyService";
import { getTradingPartyKeyByValue, TradingPartyType } from "./TradingPartyType";

const tradingPartyService = TradingPartyService.getInstance();




const TradingParty = (props) => {
    const [open, setOpen] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const loading = open && options.length === 0;
    const [infoBox, setInfoBox] = React.useState(null);
    const [editingTradingParty, setEditingTradingParty] = React.useState(null);
    const [isEditingTradingParty, setIsEditingTradingParty] = React.useState(false);


    React.useEffect(() => {

        const getTradingParties = async () => {
            const response = await tradingPartyService.getTradingParties().catch(error => {
                console.error(error)
            });

            // type filter
            let filteredParties = response.data;
            if (props.typeFilter && props.typeFilter.length > 0) {
                filteredParties = filteredParties.filter(tradingParty => props.typeFilter.find(type => tradingParty.type === type));
            }

            // firmFilter
            const firmFilter = (tradingParty) => {
                if (!tradingParty.firms || tradingParty.firms.length < 1) return false;
                return tradingParty.firms.some(firm => props.firmFilter.id === firm.id);
            }

            const leiFilter = (tradingParty) => {
                if (!tradingParty.firms || tradingParty.firms.length < 1) return false;
                return tradingParty.firms.some(firm => props.leiFilter === firm.identifier && firm.type === TradingPartyType.LEI);
            }

            if (props.firmFilter) {
                filteredParties = filteredParties.filter(firmFilter);
            }

            if (props.leiFilter) {
                filteredParties = filteredParties.filter(leiFilter);
            }


            return filteredParties;
        }

        let active = true;

        if (!loading) {
            return undefined;
        }

        (async () => {
            if (active) {
                setOptions(await getTradingParties());
            }
        })();

        return () => {
            active = false;
        };
    }, [loading, props.typeFilter, props.firmFilter]);

    React.useEffect(() => {
        if (props.value) setInfoBox(getInfoBox(props.value));
    }, [props]);

    React.useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);


    const getInfoBox = (tradingParty) => {
        return (
            <div>
                <Typography
                    variant="caption">{getTradingPartyKeyByValue(tradingParty.type)} {tradingParty.identifier}</Typography>
                {tradingParty.type === TradingPartyType.NATIONAL_ID &&
                    <div>
                        <Typography
                            variant="caption">{tradingParty.firstName} {tradingParty.lastName} ({tradingParty.birthdate})</Typography>
                    </div>
                }
            </div>
        );
    }

    const handleModalClose = () => {
        setIsEditingTradingParty(false);
        setEditingTradingParty(null);

    }

    const handleChange = (event, tradingParty) => {
        event.preventDefault();

        if (tradingParty && props.multiple === undefined) setInfoBox(getInfoBox(tradingParty));
        else setInfoBox(null);

        props.onChange({
            target: {
                name: props.name,
                value: tradingParty
            }
        });
    };


    const classes = mifirStyles();

    return (
        <>
            <Autocomplete
                id={`${props.name}-trading-party`}
                onChange={handleChange}
                multiple={props.multiple}
                open={open}
                onOpen={() => {
                    setOpen(true);
                }}
                onClose={() => {
                    setOpen(false);
                }}
                getOptionSelected={(option, value) => option.id === value.id}
                getOptionLabel={(option) => option.name}
                value={props.value || (props.multiple !== undefined ? [] : null)}
                options={options}
                loading={loading}
                autoHighlight
                openOnFocus
                debug
                renderOption={(option) => (
                    <div className={classes.tradingPartyOption}>
                        <div className={classes.optionLabel}>
                            {option.name}
                            &nbsp;
                            <Typography
                                variant="caption">({getTradingPartyKeyByValue(option.type)} {option.identifier})
                            </Typography>
                        </div>
                        <div className={classes.editTradingPartyButton}>
                            <Tooltip title="Edit">
                                <IconButton size="small" onClick={() => {
                                    setEditingTradingParty(option);
                                    setIsEditingTradingParty(true);
                                }}>
                                    <EditIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        </div>
                    </div>
                )}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={props.label}
                        value={(option) => option}
                        name={props.name}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment:
                                <InputAdornment position="end" component="div" className="MuiAutocomplete-endAdornment">
                                    {params.InputProps.endAdornment.props.children}
                                    <Tooltip title="New">
                                        <IconButton size="small" onClick={() => {
                                            setEditingTradingParty(undefined);
                                            setIsEditingTradingParty(true);
                                        }}
                                        >
                                            <AddIcon />
                                        </IconButton>
                                    </Tooltip>
                                </InputAdornment>
                        }}
                    />
                )}
            />


            {infoBox}
            <div className={classes.tradingPartySelect} />
            <Modal
                open={isEditingTradingParty}
                onClose={handleModalClose}
            >
                <Container className={classes.modalStyle} component={Paper}>
                    <TradingPartyEdit
                        tradingParty={editingTradingParty}
                        isDialog={true}
                        dialogClose={handleModalClose}
                    />
                </Container>
            </Modal>
        </>
    );
}

export default TradingParty;

TradingParty.propTypes = {
    typeFilter: PropTypes.array,
    firmFilter: PropTypes.object,
    leiFilter: PropTypes.string,
    multiple: PropTypes.bool,
    name: PropTypes.string,
    label: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.object
    ]),
}