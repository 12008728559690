import Axios from "axios";
import AuthService from "../Auth/AuthService";



class TransactionService {


    static async getTransaction(id) {
        return Axios.request({
            url: `/api/transaction/${id}`,
            method: 'get',
            headers: {
                ...AuthService.getAuthHeader()
            }
        });
    }

    static async submitTransaction(transaction) {
        return Axios.request({
            url: `/api/transaction${transaction.id ? '/' + transaction.id : ''}`,
            method: transaction.id ? 'put' : 'post',
            headers: {
                ...AuthService.getAuthHeader()
            },
            data: { ...transaction }
        });
    }

    static async deliverTransaction(id) {
        return Axios.request({
            url: `/api/report/sendInterchange/${id}`,
            headers: {
                ...AuthService.getAuthHeader()
            },
            method: 'get'
        });
    }

    static async cancelTransaction(id) {
        return Axios.request({
            url: `/api/report/sendCancellation/${id}`,
            headers: {
                ...AuthService.getAuthHeader()
            },
            method: 'get'
        });
    }

    static async generatePdfReport(id) {
        return Axios.request({
            url: `/api/report/generatePdfReport/${id}`,
            headers: {
                ...AuthService.getAuthHeader()
            },
            method: 'get',
            responseType: 'blob'
        });
    }


}

export default TransactionService;
