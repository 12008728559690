import { Button } from "@material-ui/core";
import Collapse from "@material-ui/core/Collapse";
import Container from "@material-ui/core/Container";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Paper from "@material-ui/core/Paper";
import Radio from "@material-ui/core/Radio";
import FormControl from '@material-ui/core/FormControl'
import RadioGroup from "@material-ui/core/RadioGroup";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import { Autocomplete } from '@material-ui/lab';
import Alert from "@material-ui/lab/Alert";
import * as cc from 'currency-codes';
import dateformat from "dateformat";
import React, { useState } from 'react';
import { useHistory, useParams } from "react-router-dom";
import LoadingIndicator from '../components/LoadingIndicator';
import { SettingsService } from '../Settings/SettingsService';
import TradingParty from "../TradingParty/TradingParty";
import { TradingPartyType } from "../TradingParty/TradingPartyType";
import mifirStyles from "../utils/styles";
import TransactionService from "./TransactionService";
import { OTCPostTradeIndicatorTypes } from './TransactionType';




const TransactionEdit = () => {

    const { transactionId } = useParams();
    const history = useHistory();
    const classes = mifirStyles();
    const [transaction, setTransaction] = useState({
        otcPostTradeIndicator: [],
        tradingDateTime: dateformat(new Date(), 'yyyy-mm-dd') + 'T' + dateformat(new Date(), 'HH:MM:ss'),
        buyers: [],
        sellers: [],
        currency: '',
        transmissionIndicator: false
    });
    const [action, setAction] = useState('Edit');
    const [errorMessage, setErrorMessage] = useState('');
    const [isError, setIsError] = useState(false);
    const [isIsinValid, setIsIsinValid] = useState(true);
    const [isinHelperText, setIsinHelperText] = useState('');
    const [loading, setLoading] = useState(false);
    const [executingEntityLei, setExecutingEntityLei] = useState('');

    const loadTransaction = async (id) => {
        setLoading(true);
        const response = await TransactionService.getTransaction(id).catch(error => {
            console.log(error);
        });

        if (response.data.tradingDateTime) {
            const localDate = new Date(response.data.tradingDateTime);
            response.data.tradingDateTime = dateformat(localDate, 'yyyy-mm-dd') + 'T' + dateformat(localDate, 'HH:MM:ss')
        }

        if (typeof response.data.transmissionIndicator === 'string') {
            response.data.transmissionIndicator = response.data.transmissionIndicator === "true" ? true : false;
        }

        if (!response.data.tradingDateTime) response.data.tradingDateTime = dateformat(new Date(), 'yyyy-mm-dd') + 'T' + dateformat(new Date(), 'HH:MM:ss')
        setTransaction(response.data);



        setLoading(false);
    }

    React.useEffect(() => {


        if (transaction.sellerDecisionMaker) console.log(`seller decision maker: ${transaction.sellerDecisionMaker.identifier}`);

        if (transaction.buyerDecisionMaker) console.log(`buyer decision maker: ${transaction.buyerDecisionMaker.identifier}`);
        console.log(`executing entity lei: ${executingEntityLei}`);
    }, [transaction]);


    React.useEffect(() => {
        SettingsService.getSetting("executingEntityLei").then(executingEntityLeiSetting => {
            setExecutingEntityLei(executingEntityLeiSetting.data.value);
        });
    }, []);

    React.useEffect(() => {

        if (!transactionId || !transactionId.match(/[a-f0-9]{8}-[a-f0-9]{4}-4[a-f0-9]{3}-[89aAbB][a-f0-9]{3}-[a-f0-9]{12}/)) {
            setAction("Create");
            return;
        }

        loadTransaction(transactionId).then();
    }, [transactionId]);

    const handleSubmit = async (event) => {
        event.preventDefault();

        console.log(`ISO Format DateTime is: ${new Date(transaction.tradingDateTime).toISOString()}`);
        transaction.tradingDateTime = new Date(transaction.tradingDateTime).toISOString();

        const response = await TransactionService.submitTransaction(transaction).catch(error => {
            setErrorMessage(error.data.message);
            setIsError(true);
        });
        setTransaction(response.data);
        history.push("/");
    }

    const handleInputChange = (event, val) => {
        console.log(event.target);

        const name = event.target.name;
        let value;

        let addonSet = {}

        if (name === 'transmissionIndicator' && typeof val === 'string') val = (val === 'true');
        if (name === 'isDebt' && typeof val === 'string') val = (val === 'true');

        if (val !== undefined) value = val;
        else value = event.target.value;

        console.log('setting', { [name]: value });

        setTransaction({ ...transaction, [name]: value, ...addonSet });
    }

    const currencyChanged = (event, val) => {
        let value;
        if (val !== undefined) value = val;
        else value = event.target.value;

        console.log(`setting currency to ${value}`);
        setTransaction({ ...transaction, currency: value });
    }

    const validateIsin = (event) => {
        const val = event.target.value;
        const match = val.match(/^([a-zA-Z]{2})([a-zA-Z0-9]{9})([0-9])$/);
        if (match) {
            setIsIsinValid(true);
            setIsinHelperText('');
            setTransaction({ ...transaction, [event.target.name]: val.toUpperCase() });
        }
        else {
            setIsIsinValid(false);
            setIsinHelperText('ISIN is invalid!');
        }
    }

    React.useEffect(() => {
        console.log(`transmissionIndicator is now ${transaction.transmissionIndicator} (${typeof transaction.transmissionIndicator})`);
    }, [transaction.transmissionIndicator]);

    const tradingDetails = (
        <Container className={classes.container} component={Paper} maxWidth="lg">

            <TextField
                className={classes.field}
                value={transaction.tradingDateTime}
                onChange={handleInputChange}
                label="Trading Date and Time"
                type="datetime-local"
                name="tradingDateTime"
            />

            <TextField
                className={classes.field}
                name="isin"
                label="ISIN"
                value={transaction.isin || ''}
                onChange={handleInputChange}
                onBlur={validateIsin}
                error={!isIsinValid}
                helperText={isinHelperText}
            />

            <FormControl component="fieldset">
                <FormLabel className={classes.paddingTop} component="legend">Debt Financial Instrument</FormLabel>

                <RadioGroup
                    className={classes.textField}
                    row
                    name="isDebt"
                    onChange={handleInputChange}
                    value={transaction.isDebt !== undefined ? transaction.isDebt : false}
                >
                    <FormControlLabel
                        value={true}
                        control={<Radio color="primary" />}
                        label="true"
                        labelPlacement="end"
                    />
                    <FormControlLabel
                        value={false}
                        control={<Radio color="primary" />}
                        label="false"
                        labelPlacement="end"
                    />

                </RadioGroup>
            </FormControl>

            <TextField
                className={classes.field}
                value={+transaction.quantity || ''}
                onChange={handleInputChange}
                label={transaction.isDebt ? "Nominal value" : "Quantity"}
                type="number"
                name="quantity"
            />

            <Autocomplete
                className={classes.field}
                name="currency"
                options={[...cc.codes(), '']}
                getOptionLabel={option => option}
                value={transaction.currency}
                onChange={currencyChanged}
                renderInput={params => (
                    <TextField
                        {...params}
                        label={transaction.isDebt ? "Nominal Currency" : "Currency"}
                        name="currency"
                    />
                )}
            />

            <TextField
                className={classes.field}
                value={+transaction.price || ''}
                onChange={handleInputChange}
                label={transaction.isDebt ? 'Price Percentage' : 'Price'}
                type='number'
                name='price'
            />



            {transaction.isDebt &&
                <TextField
                    className={classes.field}
                    value={+transaction.netAmount || ''}
                    onChange={handleInputChange}
                    label='Net Amount'
                    type='number'
                    name='netAmount'
                />
            }

            <Autocomplete
                name="otcPostTradeIndicator"
                onChange={(event, value) => {
                    event.target.name = "otcPostTradeIndicator"
                    handleInputChange(event, value);
                }}
                multiple
                getOptionSelected={(option, value) => option === value}
                getOptionLabel={option => OTCPostTradeIndicatorTypes[option]}
                value={transaction.otcPostTradeIndicator}
                options={Object.keys(OTCPostTradeIndicatorTypes)}
                renderInput={params =>
                    <TextField
                        {...params}
                        label="OTC Post Trade Indicators"
                        value={option => option}
                        name="otcPostTradeIndicator"
                    />
                }
            />


        </Container>
    );

    const trader = (
        <Container className={classes.container} component={Paper} maxWidth="lg">

            <TradingParty
                value={transaction.buyers}
                onChange={handleInputChange}
                name="buyers"
                label="Buyer"
                multiple
            />

            <TradingParty
                value={transaction.buyerDecisionMaker}
                onChange={handleInputChange}
                name="buyerDecisionMaker"
                label="Buyer Decision Maker"
                typeFilter={[TradingPartyType.NATIONAL_ID, TradingPartyType.LEI]}
            />


            <TradingParty
                value={transaction.sellers}
                onChange={handleInputChange}
                name="sellers"
                label="Seller"
                multiple
            />

            <TradingParty
                value={transaction.sellerDecisionMaker}
                onChange={handleInputChange}
                name="sellerDecisionMaker"
                label="Seller Decision Maker"
                typeFilter={[TradingPartyType.NATIONAL_ID, TradingPartyType.LEI]}
            />




            {
                ((transaction.buyerDecisionMaker && transaction.buyerDecisionMaker.type === TradingPartyType.LEI && transaction.buyerDecisionMaker.identifier === executingEntityLei)
                    || (transaction.sellerDecisionMaker && transaction.sellerDecisionMaker.type === TradingPartyType.LEI && transaction.sellerDecisionMaker.identifier === executingEntityLei))
                &&
                <TradingParty
                    value={transaction.decisionMakerWithinFirm}
                    onChange={handleInputChange}
                    name="decisionMakerWithinFirm"
                    label="decision Maker Within Firm"
                    leiFilter={executingEntityLei}
                />
            }

            <TradingParty
                value={transaction.executingPerson}
                onChange={handleInputChange}
                name="executingPerson"
                label='Executing Person (default: "NORE")'
                typeFilter={[TradingPartyType.NATIONAL_ID]}
            />

        </Container>
    );

    if (loading) return <LoadingIndicator />;
    else return (
        <form className={classes.spacingBottom} onSubmit={handleSubmit} noValidate autoComplete="off">

            <Typography className={classes.title} variant="h5">{action} transaction</Typography>
            <Collapse open={isError}>
                <Alert color="error">{errorMessage}</Alert>
            </Collapse>



            {tradingDetails}

            <Typography className={classes.title} variant="h5">Trader</Typography>
            {trader}

            <Typography className={classes.title} variant="h5">Transmission</Typography>
            <Container className={classes.container} component={Paper} maxWidth="lg">


                <FormLabel className={classes.formLabel} component="legend">Transmission  of order indicator</FormLabel>
                <RadioGroup
                    className={classes.textField}
                    row
                    name="transmissionIndicator"
                    onChange={handleInputChange}
                    value={transaction.transmissionIndicator !== undefined ? transaction.transmissionIndicator : true}
                >
                    <FormControlLabel
                        value={true}
                        control={<Radio color="primary" />}
                        label="true"
                        labelPlacement="end"
                    />
                    <FormControlLabel
                        value={false}
                        control={<Radio color="primary" />}
                        label="false"
                        labelPlacement="end"
                    />

                </RadioGroup>

                {transaction.transmissionIndicator === true && <>

                    <TradingParty
                        value={transaction.buyerTransmittingFirm}
                        onChange={handleInputChange}
                        name="buyerTransmittingFirm"
                        label="Buyer Transmitting Firm"
                        typeFilter={[TradingPartyType.LEI]}
                    />

                    <TradingParty
                        value={transaction.sellerTransmittingFirm}
                        onChange={handleInputChange}
                        name="sellerTransmittingFirm"
                        label="Seller Transmitting Firm"
                        typeFilter={[TradingPartyType.LEI]}
                    />

                </>}


            </Container>

            <Button className={classes.actionButton} variant="contained" color="primary" type="submit"><SaveIcon
                fontSize="small" />&nbsp;{transactionId ? 'Save' : 'Create'}</Button>
            <Button className={classes.actionButton} variant="contained" color="secondary"
                onClick={() => window.history.go(-1)}><CancelIcon />&nbsp;Cancel</Button>
        </form>
    );

};

export default TransactionEdit;
